import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CONFIG from './../../../SYSTEM/Config.js'

import ProductCard from './../../../APP/COMPONENTS/ContentParts/ProductCard';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class ProductsStaticPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CAT: props.CAT,
            IDS: props.IDS,
            CAROUSEL: false
        }
    }

    renderMoreButton = (URL) => {
        //if (WIDTH <= 1200) {
        return (
            <div className="main_more_holder">
                <Link to={ URL } className="main_more">
                    <span>Ð•Ñ‰Ñ‘</span><img src="/system_images/angle_down.svg" />
                </Link>
            </div>
        )
        //}
    }

    renderCarousel() {
        let PRODUCT_CARDS = [];
        let TOTAL_NORMALS = 0;
        let LENGTH = 5;

        for (let i = 0; i < this.state.IDS.length; i++) {
            if (typeof this.state.IDS[i] !== "undefined") {
                if (typeof this.props.PRODUCTS[this.state.IDS[i]] !== "undefined") {
                    TOTAL_NORMALS++;
                    ///if (PRODUCT_CARDS.length < LENGTH) {
                    PRODUCT_CARDS.push(
                        <div key={ i } className="card_holder"><ProductCard ID={ this.state.IDS[i] } /></div>
                    );
                    // }
                }
            }
        }

        let CAROUSEL = (
            <>
                <Link className="static_header" to={ '/' + this.state.CAT.url }>
                    { this.state.CAT.title }
                </Link>
                <div className="products_grid" ref={ c => (this.slider = c) }>
                    { PRODUCT_CARDS }
                </div>
                {/* {this.renderMoreButton('/' + this.state.CAT.url)} */ }
            </>
        );


        return CAROUSEL;
    }

    render() {
        return this.renderCarousel();
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsStaticPreview))
