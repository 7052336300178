import React, {Component} from 'react';
import {Switch, Route,withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';
import InputMask from "react-input-mask";

import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ScrollPreview from './../../APP/COMPONENTS/ContentParts/ScrollPreview'
import CatPreview from './../../APP/COMPONENTS/ContentParts/CatPreview'
import Adverts from './../../APP/COMPONENTS/ContentParts/Adverts'

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';
import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';

function mapStateToProps(state, myProps) {
    return {
        DO_SEARCH: state.DO_SEARCH,
        PRODUCTS: state.PRODUCTS,
        POPULAR: state.POPULAR,
        TOKEN: state.TOKEN
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class HomePage extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            PRODUCTS: props.PRODUCTS,
            PHONE: '+7-___-___-__-__',
            NAME: '',
            EMAIL: ''
        }
    }

    componentDidMount()
    {
        this.props.closeAllModals();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
        if (this.props.PRODUCTS !== prevProps.PRODUCTS) {
            this.setState({PRODUCTS: true});
        }
    }

    checkUpdates()
    {
        if (this.state.PRODUCTS !== this.props.PRODUCTS) {
            if (!this.state.PRODUCTS) {
                this.setState({UPDATED: true});
            }
        }
    }

    renderAdvert()
    {
        return <Adverts />;
    }

    scrollToForm = () =>
    {
        document.querySelector('#work_form').scrollIntoView({behavior: "smooth"});
    }

    renderFirstLine = () =>
    {
        return (
            <div className="main_first_line">
                <div className="main_first_triangle"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <img src="/system_images/real_bread.jpg" alt="ÐœÑ‹ Ð¿ÐµÑ‡Ñ‘Ð¼ Ð½Ð°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ñ…Ð»ÐµÐ±" />
                        </div>
                        <div className="col-6">
                            <div className="main_h1_holder">
                                <h1 className="main_h1">
                                    ÐœÑ‹ Ð¿ÐµÑ‡Ñ‘Ð¼ Ð½Ð°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ñ…Ð»ÐµÐ±
                                </h1>
                                <div className="main_h1_after">
                                    Ð¡Ð²ÐµÐ¶Ð¸Ð¹ Ñ…Ð»ÐµÐ± Ð¸ Ð²Ñ‹Ð¿ÐµÑ‡ÐºÐ° Ð¼ÐµÑÑ‚Ð½Ð¾Ð³Ð¾ Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´ÑÑ‚Ð²Ð°. Ð’ÐµÑÑŒ Ñ…Ð»ÐµÐ±, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð¼Ñ‹ Ð²Ñ‹Ð¿ÐµÐºÐ°ÐµÐ¼, Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´Ð¸Ñ‚ÑÑ Ð¸Ð· Ð¼ÐµÑÑ‚Ð½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð², Ð¼Ñ‹ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·ÑƒÐµÐ¼ ÑÐ°Ð¼Ñ‹Ðµ ÑÐ²ÐµÐ¶Ð¸Ðµ Ð¾Ñ€Ð³Ð°Ð½Ð¸Ñ‡ÐµÑÐºÐ¸Ðµ Ð¸Ð½Ð³Ñ€ÐµÐ´Ð¸ÐµÐ½Ñ‚Ñ‹.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCatPreview = () =>
    {
        let CAT = {id: 0, title: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾Ðµ', url: 'popular'};
        let products = this.props.POPULAR;

        return (
            <div className="main_cat_preview">
                <div className="main_cat_tr_top"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="main_cat_head">
                                ÐšÐ°Ñ‚Ð°Ð»Ð¾Ð³
                            </div>
                            <ProductsScrollPreview CAT={CAT} IDS={products} />
                        </div>
                    </div>
                </div>
                <div className="main_cat_tr_bottom"></div>
            </div>
        );
    }

    renderLKIntro = () =>
    {
        return (
            <div className="main_lk_intro">
                <div className="container">
                        <div className="row">
                            <div className="col-5">
                            </div>
                            <div className="col-7">
                                <div className="main_lk_head">
                                    ÐœÑ‹ Ñ€Ð°Ð´Ñ‹<br/>Ð½Ð¾Ð²Ñ‹Ð¼ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°Ð¼!
                                </div>
                                <div className="main_lk_text">
                                    Ð—Ð°Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð¸Ñ€ÑƒÐ¹Ñ‚ÐµÑÑŒ<br/>Ð¸ ÑÐ¾Ð²ÐµÑ€ÑˆÐ°Ð¹Ñ‚Ðµ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸!
                                </div>
                                <Link to="/personal" className="main_lk_link">
                                    ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð»Ð¸Ñ‡Ð½Ð¾Ð¼Ñƒ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ñƒ
                                </Link>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

    doSubmit = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'recordRequest',
                token:      this.props.TOKEN,
                phone:      this.state.PHONE,
                email:      this.state.EMAIL,
                name:       this.state.NAME
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SUCCESS: true, NAME: '', PHONE: '', EMAIL: '', COMPANY: ''})
            }
        } catch (error) {
        }
    }

    recordPhone = (phone) => 
    {
        this.setState({PHONE: phone.target.value});
    }

    recordEmail = (email) => 
    {
        this.setState({EMAIL: email.target.value});
    }

    recordName = (name) => 
    {
        this.setState({NAME: name.target.value});
    }

    validateEmail = (email) =>
    {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    getSubmitButton()
    {
        if (this.state.SUCCESS) {
            return (
                <div className="work_success">
                    Ð’Ð°Ñˆ Ð·Ð°Ð¿Ñ€Ð¾Ñ ÑƒÑÐ¿ÐµÑˆÐ½Ð¾ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÐµÐ½! ÐÐ°Ñˆ Ð¼ÐµÐ½ÐµÐ´Ð¶ÐµÑ€ ÑÐ²ÑÐ¶ÐµÑ‚ÑÑ Ñ Ð’Ð°Ð¼Ð¸ Ð² Ð±Ð»Ð¸Ð¶Ð°Ð¹ÑˆÐµÐµ Ð²Ñ€ÐµÐ¼Ñ!
                </div>
            )
        } else {
            let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

            if (((phoneNumber.length == 11) || (this.validateEmail(this.state.EMAIL))) && (this.state.NAME.length > 1)) {
                return (
                    <a className="work_form_button" onClick={() => this.doSubmit()}>
                        ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ
                    </a>
                );
            } else {
                return (
                    <a className="work_form_button inactive-btn">
                        ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ
                    </a>
                );
            }
        }
        
    }

    renderWorkForm = () =>
    {
        return (
            <div id="work_form" className="work_form">
                <div className="work_form_top"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="work_head">
                                Ð¥Ð¾Ñ‚Ð¸Ñ‚Ðµ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡Ð°Ñ‚ÑŒ?
                            </div>
                            <div className="work_text">
                                ÐžÑÑ‚Ð°Ð²ÑŒÑ‚Ðµ ÐºÑ€Ð°Ñ‚ÐºÑƒÑŽ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÑŽ Ð¾ ÑÐµÐ±Ðµ,<br/>Ð¸ Ð¼Ñ‹ Ð²ÑÐºÐ¾Ñ€Ðµ ÑÐ²ÑÐ¶ÐµÐ¼ÑÑ Ñ Ð²Ð°Ð¼Ð¸
                            </div>
                            <div className="work_form_form">
                                <input placeholder="Ð˜Ð¼Ñ" type="text" onChange={(text) => this.recordName(text)} />
                                <InputMask placeholder="ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°" type="tel" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
                                <input placeholder="Ð˜Ð»Ð¸ ÑÐ»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð°Ñ Ð¿Ð¾Ñ‡Ñ‚Ð°" type="email" onChange={(text) => this.recordEmail(text)} />
                                {this.getSubmitButton()}
                            </div>
                            
                        </div>
                        <div className="col-6">
                            <img src="/system_images/bread2.jpg" alt="Ð¥Ð¾Ñ‚Ð¸Ñ‚Ðµ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡Ð°Ñ‚ÑŒ?" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderBottom = () =>
    {
        return (
            <div className="main_bottom">
                <img className="curve1" src="/system_images/curve1.png" />
                <img className="curve2" src="/system_images/curve2.png" />
                <h1 className="main_h1">Ðž Ð½Ð°Ñ</h1>
                <div className="main_text">
                    ÐœÑ‹ Ð°ÐºÑ†ÐµÐ½Ñ‚Ð¸Ñ€ÑƒÐµÐ¼ ÑÐ²Ð¾Ñ‘ Ð²Ð½Ð¸Ð¼Ð°Ð½Ð¸Ðµ Ð½Ð° Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´ÑÑ‚Ð²Ðµ Ñ€Ð°Ð·Ð»Ð¸Ñ‡Ð½Ñ‹Ñ… ÐºÐ¾Ð½Ð´Ð¸Ñ‚ÐµÑ€ÑÐºÐ¸Ñ… Ð¸ Ñ…Ð»ÐµÐ±Ð¾Ð±ÑƒÐ»Ð¾Ñ‡Ð½Ñ‹Ñ… Ð¸Ð·Ð´ÐµÐ»Ð¸Ð¹ Ð²Ñ‹ÑÐ¾ÐºÐ¾Ð³Ð¾ ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð° Ð¸Ð· Ð¾Ñ‚Ð¾Ð±Ñ€Ð°Ð½Ð½Ñ‹Ñ… Ð¿Ñ€Ð¾Ñ„ÐµÑÑÐ¸Ð¾Ð½Ð°Ð»Ð°Ð¼Ð¸ ÑÐºÐ¾Ð»Ð¾Ð³Ð¸Ñ‡ÐµÑÐºÐ¸ Ð±ÐµÐ·Ð¾Ð¿Ð°ÑÐ½Ñ‹Ñ… Ð¸Ð½Ð³Ñ€ÐµÐ´Ð¸ÐµÐ½Ñ‚Ð¾Ð² Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ð¾Ð³Ð¾ Ð¿Ñ€Ð¾Ð¸ÑÑ…Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ. Ð ÑƒÑ‡Ð½Ð¾Ð¹ Ñ‚Ñ€ÑƒÐ´ Ð¼Ð°ÑÑ‚ÐµÑ€Ð¾Ð², Ð´ÐµÐ»Ð°ÐµÑ‚ ÐºÐ°Ð¶Ð´ÑƒÑŽ Ð±ÑƒÐ»Ð¾Ñ‡ÐºÑƒ, ÐºÐ°Ð¶Ð´Ñ‹Ð¹ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚ ÑƒÐ½Ð¸ÐºÐ°Ð»ÑŒÐ½Ñ‹Ð¼ Ð¸ Ð²ÐºÑƒÑÐ½Ñ‹Ð¼.
                </div>
                <Link to="/company/info">
                    ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ
                </Link>
            </div>
        )
    }

	render() {
        if (!this.state.PRODUCTS) {
            return false;
        }
        
    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <Helmet>
                    <title>ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ð¥Ð»ÐµÐ± - ÐŸÐµÐºÐ°Ñ€Ð½Ñ Ð² ÐœÐ¾ÑÐºÐ²Ðµ Ð´Ð»Ñ Ñ‡Ð°ÑÑ‚Ð½Ñ‹Ñ… Ð»Ð¸Ñ† Ð¸ Ð¾Ð¿Ñ‚Ð°</title>
                    <meta name="description" content="ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ð¥Ð»ÐµÐ± - ÐŸÐµÐºÐ°Ñ€Ð½Ñ Ð² ÐœÐ¾ÑÐºÐ²Ðµ Ð´Ð»Ñ Ñ‡Ð°ÑÑ‚Ð½Ñ‹Ñ… Ð»Ð¸Ñ† Ð¸ Ð¾Ð¿Ñ‚Ð°. Ð’Ñ‹ÑÐ¾ÐºÐ¾Ðµ ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð¾, ÑƒÐ½Ð¸ÐºÐ°Ð»ÑŒÐ½Ñ‹Ðµ Ñ€ÐµÑ†ÐµÐ¿Ñ‚Ñ‹" />
                    <meta name="keywords" content="ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ð¥Ð»ÐµÐ± - ÐŸÐµÐºÐ°Ñ€Ð½Ñ Ð² ÐœÐ¾ÑÐºÐ²Ðµ Ð´Ð»Ñ Ñ‡Ð°ÑÑ‚Ð½Ñ‹Ñ… Ð»Ð¸Ñ† Ð¸ Ð¾Ð¿Ñ‚Ð°" />
                </Helmet>
                {this.renderAdvert()}
                <ScrollPreview ID={-3} />
                <ScrollPreview ID={-1} />
                
                <CatPreview />
                <ScrollPreview ID={-2} />
                {/*}
                        <div className="main_page_intro">
                            <a className="intro_to_form" onClick={() => this.scrollToForm()}>
                                Ð¥Ð¾Ñ‚Ð¸Ñ‚Ðµ Ñ Ð½Ð°Ð¼Ð¸<br/>ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡Ð°Ñ‚ÑŒ?
                            </a>
                        </div>
                        {this.renderFirstLine()}
                        {this.renderCatPreview()}
                        {this.renderLKIntro()}
                        {this.renderWorkForm()}
                */}
                {this.renderBottom()}
                <ModalsContainer />
            </CSSTransitionGroup> 
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(HomePage))
