import React, { Component } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CSSTransitionGroup } from 'react-transition-group';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import InnerProduct from './../../APP/COMPONENTS/ContentParts/InnerProduct';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        CATS: state.CATS,
        DO_SEARCH: state.DO_SEARCH,
        TOKEN: state.TOKEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            URL: props.url,
            PRODUCT: false,
            ERROR: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.url !== prevProps.url) {
            this.props.closeAllModals();

            if (this.props.CATS) {
                this.setState({ PRODUCT: false, URL: this.props.url, ERROR: false });
                let COMPONENT = this;
                this.interval = setInterval(function () {
                    COMPONENT.getProduct()
                }, 50);
            }
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    componentDidMount() {
        this.props.closeAllModals();

        let COMPONENT = this;
        this.interval = setInterval(function () {
            COMPONENT.getProduct()
        }, 50);
    }

    getProduct = () => {
        if (!this.state.PRODUCT) {
            if (this.props.PRODUCTS) {
                let PRODUCT = false;

                for (let ID in this.props.PRODUCTS) {
                    if (this.props.PRODUCTS[ID].url === this.state.URL) {
                        PRODUCT = this.props.PRODUCTS[ID];
                        PRODUCT.CAT = this.getParentCat(PRODUCT.group);
                    }
                }

                if (PRODUCT) {
                    this.setState({ PRODUCT: PRODUCT, ERROR: false });
                } else {
                    this.getWebProduct();
                }
            }
        }
    }

    getWebProduct = async () => {
        try {
            let SEND_DATA = {
                action: 'getProductByUrl',
                url: this.state.URL,
                token: this.props.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                let PRODUCT = data;
                PRODUCT.CAT = this.getParentCat(PRODUCT.group);
                this.setState({ PRODUCT: PRODUCT, ERROR: false });
            } else {
                this.setState({ PRODUCT: 'NONE', ERROR: true });
            }
        } catch (error) {
            this.setState({ PRODUCT: 'NONE', ERROR: true });
        }
    }

    getParentCat(id) {
        let CAT = false;
        for (let i = 0; i < this.props.CATS.length; i++) {
            if (!CAT) {

                let PARENT_CAT = this.props.CATS[i];
                if (PARENT_CAT.id === id) {
                    CAT = PARENT_CAT;
                }

                if (!CAT) {
                    if (PARENT_CAT.children.length) {
                        for (let j = 0; j < PARENT_CAT.children.length; j++) {
                            let CHILD_CAT = PARENT_CAT.children[j];
                            if (CHILD_CAT.id === id) {
                                CAT = CHILD_CAT;
                            }
                        }
                    }
                }

            }
        }

        return CAT;
    }

    render() {
        if (!this.state.PRODUCT) {
            return <InnerLoader />
        }

        if (this.state.ERROR) {
            return <E404 />;
        }

        return (
            <CSSTransitionGroup
                transitionAppear={ true }
                transitionAppearTimeout={ 600 }
                transitionEnterTimeout={ 600 }
                transitionLeaveTimeout={ 200 }
                transitionName={ 'SlideIn' }
            >
                <div className="right-part">
                    <Helmet>
                        <title>{ this.state.PRODUCT.meta_title }</title>
                        <meta name="description" content={ this.state.PRODUCT.meta_description } />
                        <meta name="keywords" content={ this.state.PRODUCT.meta_keywords } />
                    </Helmet>
                    <div className="go_back_btn">
                        <Link to="/" className="return_button">
                            <img src="/system_images/a_l.png" />
                        </Link>
                    </div>
                    <InnerProduct product={ this.state.PRODUCT } />

                    <ModalsContainer />
                </div>
            </CSSTransitionGroup>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Product))
