import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Bonuses extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            CODE: this.props.USER.CODE,
            BONUSES: this.props.USER.BONUSES,
            SHARE_BONUSES: 0,
            SHARE: false,
            HOW: false,
            HISTORY: false,
            BONUSES_STORY: false,
            PHONE: false,
            NEW_CODE: false,
            SHARE_CODE: false,
            SHARE_LINK: false
        };

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.setHowRef = this.setHowRef.bind(this);
        this.setShareRef = this.setShareRef.bind(this);
        this.setCodeRef = this.setCodeRef.bind(this);
        this.setShareCodeRef = this.setShareCodeRef.bind(this);
        this.setShareLinkRef = this.setShareLinkRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.getHistory();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ((this.props.USER.BONUSES !== prevProps.USER.BONUSES) || (this.props.USER.CODE !== prevProps.USER.CODE)) {        
            this.setState({BONUSES: this.props.USER.BONUSES, CODE: this.props.USER.CODE});
        }
    }

    getHistory = async () =>
    {
        try {
            let SEND_DATA = {
                action:    'bonusesStory',
                apikey:    this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({BONUSES_STORY: data.bonuses_story})
            }
        } catch (error) {
            console.log('BAD_BONUSES_STORY');
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    setHowRef(node) {
        this.how = node;
    }

    setShareRef(node) {
        this.share = node;
    }

    setCodeRef(node) {
        this.code = node;
    }

    setShareCodeRef(node) {
        this.share_code = node;
    }

    setShareLinkRef(node) {
        this.share_link = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.HISTORY) {
                this.setState({HISTORY: false});
            }
        }

        if (this.how && !this.how.contains(event.target)) {
            if (this.state.HOW) {
                this.setState({HOW: false});
            }
        }

        if (this.share && !this.share.contains(event.target)) {
            if (this.state.SHARE) {
                this.setState({SHARE: false});
            }
        }

        if (this.code && !this.code.contains(event.target)) {
            if (this.state.EDIT) {
                this.setState({EDIT: false});
            }
        }

        if (this.share_code && !this.share_code.contains(event.target)) {
            if (this.state.SHARE_CODE) {
                this.setState({SHARE_CODE: false});
            }
        }

        if (this.share_link && !this.share_link.contains(event.target)) {
            if (this.state.SHARE_LINK) {
                this.setState({SHARE_LINK: false});
            }
        }
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderHowHead()
    {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({HOW: false})}>
                        <img src={'/system_images/angle_left.png'} />
                    </a>
                    <h4>ÐšÐ°Ðº Ð·Ð°Ñ‡Ð¸ÑÐ»ÑÑŽÑ‚ÑÑ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹</h4>
                </div>
            );
        } else {
            return (
                <div>
                    <a className="close-modal" onClick={() => this.setState({HOW: false})}>
                        <img src={'/system_images/close.png'} />
                    </a>

                    <div className="how_bonuses_head">ÐšÐ°Ðº Ð·Ð°Ñ‡Ð¸ÑÐ»ÑÑŽÑ‚ÑÑ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹</div>
                </div>
            );
        }
    }

    renderHowAppend()
    {
        if (this.state.HOW) {
            let CLASSES = 'form-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'form-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} id="bonuses-pop" ref={this.setHowRef}>
                    {this.renderHowHead()}

                    <div className="how_bonuses_text">
                        Ð—Ð° ÐºÐ°Ð¶Ð´ÑƒÑŽ Ð²Ð°ÑˆÑƒ Ð¿Ð¾ÐºÑƒÐ¿ÐºÑƒ Ð² ECOMARKET.RU Ð¼Ñ‹ Ð½Ð°Ñ‡Ð¸ÑÐ»ÑÐµÐ¼ Ð²Ð°Ð¼ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹ Ð½Ð° Ð²Ð°Ñˆ <a onClick={() => this.setState({HOW: false})}>ÑÑ‡ÐµÑ‚ Ð² Ð»Ð¸Ñ‡Ð½Ð¾Ð¼ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ðµ.</a> ÐšÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ð½Ð°Ñ‡Ð¸ÑÐ»ÐµÐ½Ð½Ñ‹Ñ… ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² Ð·Ð°Ð²Ð¸ÑÐ¸Ñ‚ Ð¾Ñ‚ ÑÑƒÐ¼Ð¼Ñ‹, Ð½Ð° ÐºÐ¾Ñ‚Ð¾Ñ€ÑƒÑŽ Ð±Ñ‹Ð»Ð° Ð¿Ñ€Ð¾Ð¸Ð·Ð²ÐµÐ´ÐµÐ½Ð° Ð²Ð°ÑˆÐ° Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ°, Ð° Ð¸Ð¼ÐµÐ½Ð½Ð¾:
                    </div>

                    <div className="bonuses-info-table">
                        <div>
                            2000-2500 Ñ€ÑƒÐ± - 0,5%
                            <br/>
                            2501-3500 Ñ€ÑƒÐ± - 1%
                            <br/>
                            3501-4500 Ñ€ÑƒÐ± - 1,5%
                            <br/>
                            4501-5500 Ñ€ÑƒÐ± - 2%
                            <br/>
                            5501-7000 Ñ€ÑƒÐ± - 2,5%
                            <br/>
                            7001-8500 Ñ€ÑƒÐ± - 3%
                            <br/>
                            8501-10000 Ñ€ÑƒÐ± - 4%
                        </div>
                        <div>
                            10001-12000 Ñ€ÑƒÐ± - 5%
                            <br/>
                            12001-14000 Ñ€ÑƒÐ± - 6%
                            <br/>
                            14001-16000 Ñ€ÑƒÐ± - 7% 
                            <br/>
                            17001-18000 Ñ€ÑƒÐ± - 8%
                            <br/>
                            18001-20000 Ñ€ÑƒÐ± - 9%
                            <br/>
                            20001 Ñ€ÑƒÐ± Ð¸ Ð²Ñ‹ÑˆÐµ - 10%
                        </div>
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        <strong>
                            Ð’Ñ‹ Ð´ÐµÐ»Ð°ÐµÑ‚Ðµ Ð¿Ð¾ÑÑ‚ Ð¸Ð»Ð¸ ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð² Instagram
                        </strong>
                        <br/>
                        Ñ Ð¾Ð±Ð·Ð¾Ñ€Ð¾Ð¼ Ð½Ð°ÑˆÐ¸Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÑ‚Ð¾Ð² Ð¸ Ð¾Ñ‚Ð¼ÐµÑ‡Ð°ÐµÑ‚Ðµ Ð½Ð°ÑˆÑƒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ @ecomarket.ru.
                        <br/>
                        ÐœÑ‹ Ð½Ð°Ñ‡Ð¸ÑÐ»ÑÐµÐ¼ Ð²Ð°Ð¼ 250 ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² Ð·Ð° Ð¿ÐµÑ€Ð²Ñ‹Ð¹ Ð¿Ð¾ÑÑ‚ Ð¸Ð»Ð¸ ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð¸ 150 ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² Ð·Ð° Ð¿Ð¾ÑÐ»ÐµÐ´ÑƒÑŽÑ‰Ð¸Ðµ Ð¿ÑƒÐ±Ð»Ð¸ÐºÐ°Ñ†Ð¸Ð¸ Ð¿Ñ€Ð¸ Ð´Ð°Ð»ÑŒÐ½ÐµÐ¹ÑˆÐ¸Ñ… Ð·Ð°ÐºÐ°Ð·Ð°Ñ….
                        <br/>
                        1 Ð·Ð°ÐºÐ°Ð· - 1 Ð½Ð°Ñ‡Ð¸ÑÐ»ÐµÐ½Ð¸Ðµ, 1 ÑÐºÐ¾Ð±Ð°Ð»Ð» = 1 Ñ€ÑƒÐ±Ð»ÑŒ.
                        <br/>
                        Ð•ÑÐ»Ð¸ Ð²Ð°ÑˆÐ° ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ð° Ð·Ð°ÐºÑ€Ñ‹Ñ‚Ð°, Ð½Ð°Ð¿Ð¸ÑˆÐ¸Ñ‚Ðµ Ð½Ð°Ð¼ Ð¸ Ð½Ð°Ñˆ Ð¼ÐµÐ½ÐµÐ´Ð¶ÐµÑ€ Ð¿Ð¾ Ð¸Ð½ÑÑ‚Ð°Ð³Ñ€Ð°Ð¼Ñƒ Ð´Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑÑ Ðº Ð²Ð°Ð¼.
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        Ð•ÑÐ»Ð¸ <strong>ÐºÑƒÑ€ÑŒÐµÑ€ Ð·Ð°Ð±Ñ‹Ð» Ñƒ Ð²Ð°Ñ Ñ‚ÐµÑ€Ð¼Ð¾Ð¿Ð°ÐºÐµÑ‚ Ñ Ñ…Ð¾Ð»Ð¾Ð´ÐºÐ°Ð¼Ð¸</strong>, Ð¿Ð¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚Ðµ Ð½Ð°Ð¼ 8(495)4450505 (Ð´Ð¾Ð± 106) Ð¸Ð»Ð¸ Ð½Ð°Ð¿Ð¸ÑˆÐ¸Ñ‚Ðµ Ð² WhatsApp +7 916 1150909 Ð¸ Ð¼Ñ‹ Ð½Ð°Ñ‡Ð¸ÑÐ»Ð¸Ð¼ Ð²Ð°Ð¼ 200 ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð², Ð° Ð¿Ð°ÐºÐµÑ‚ Ð·Ð°Ð±ÐµÑ€Ñ‘Ð¼ Ð¿Ñ€Ð¸ ÑÐ»ÐµÐ´ÑƒÑŽÑ‰ÐµÐ¹ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐµ.
                    </div>

                    <div className="bonus_separator"></div>

                    <div className="how_bonuses_text_small">
                        ÐžÑ‚Ð´Ð°Ð¹Ñ‚Ðµ ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð½Ñ‹Ðµ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ¸, Ð¸ Ð¼Ñ‹ Ð½Ð°Ñ‡Ð¸ÑÐ»Ð¸Ð¼ Ð²Ð°Ð¼ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹:
                        <br/>
                        1 Ð¿Ð°Ð»ÑŒÑ‡Ð¸ÐºÐ¾Ð²Ð°Ñ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ° - 10 ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð².
                    </div>

                    <div className="insta_block">
                        <div className="insta_image_holder">
                            <img src="/system_images/green_instagram.png" />
                        </div>
                        <div>
                            <div className="green_imsta">
                                ÐŸÐ¾Ð´Ð¿Ð¸ÑÑ‹Ð²Ð°Ð¹Ñ‚ÐµÑÑŒ Ð½Ð° Ð½Ð°Ñˆ Instagram,
                            </div>
                            <div className="black_insta">
                                ÑƒÑ‡Ð°ÑÑ‚Ð²ÑƒÐ¹Ñ‚Ðµ Ð² ÐºÐ¾Ð½ÐºÑƒÑ€ÑÐ°Ñ… Ð¸ Ð²Ñ‹Ð¸Ð³Ñ€Ñ‹Ð²Ð°Ð¹Ñ‚Ðµ.
                            </div>
                        </div>
                    </div>

                    <div className="bonus_warning">Ð’Ð½Ð¸Ð¼Ð°Ð½Ð¸Ðµ! Ð­ÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹ ÑÐ³Ð¾Ñ€Ð°ÑŽÑ‚ Ð¿Ð¾ Ð¸ÑÑ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸ 30 Ð´Ð½ÐµÐ¹</div>

                </div>
            );
        }
    }

    renderHistoryHead()
    {
        if (WIDTH <= 760) {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.setState({HISTORY: false})}>
                        <img src={'/system_images/angle_left.png'} />
                    </a>
                    <h4>Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð½Ð°Ñ‡Ð¸ÑÐ»ÐµÐ½Ð¸Ñ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²</h4>
                </div>
            );
        }
    }

    renderHistory()
    {
        if (this.state.HISTORY) {
            let STORY_ITEMS = [];

            if (this.state.BONUSES_STORY) {
                for (let i = 0; i < this.state.BONUSES_STORY.length; i++) {
                    let ITEM = this.state.BONUSES_STORY[i];
                    STORY_ITEMS.push(
                        <div className="story-row" key={i} ref={this.setHistoryRef}>
                            <div className="story-amount">{ITEM.AMOUNT} {this.num_title(ITEM.AMOUNT, ['ÑÐºÐ¾Ð±Ð°Ð»Ð»', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð°', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²'])}</div>
                            <div className="story-how">{ITEM.HUMAN_DATE}  {ITEM.WAY}</div>
                        </div>
                    );
                }
            }

            let CLASSES = 'form-pop-append';
            if (WIDTH <= 760) {
                CLASSES = 'form-pop-append fixed-full-screen';
            }

            return (
                <div className={CLASSES} id="story-pop">
                    {this.renderHistoryHead()}
                    {STORY_ITEMS}
                </div>
            );
        }
    }

    doShare = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'shareBonuses',
                apikey:     this.props.USER.TOKEN,
                phone:      this.state.PHONE,
                bonuses:    this.state.SHARE_BONUSES
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({SHARE: false, SHARE_BONUSES: 0, PHONE: false, BONUSES: data.BONUSES});
                this.props.setBonusesAmount(data.BONUSES);
            } else {
                return false;
            }
        } catch (error) {
            console.warn('PROBLEM WHILE SHARING');
        }
    }

    recordPhone = (phone) => 
    {
        this.setState({PHONE: phone.target.value});
    }

    recordBonuses = (bonuses) => 
    {
        this.setState({SHARE_BONUSES: bonuses.target.value});
    }

    changeCode = (code) => 
    {
        this.setState({NEW_CODE: code.target.value});
    }

    changeCodeNow = async () =>
    {
        if (this.state.NEW_CODE.length > 5) {
            try {
                let SEND_DATA = {
                    action:    'changeCode',
                    code:       this.state.NEW_CODE,
                    apikey:     this.props.USER.TOKEN
                };

                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (data !== false) {
                    this.props.doAuth(data.user);
                    this.setState({EDIT: false})
                }
            } catch (error) {
                console.log('BAD PRODUCTS TIMER');
            }
        }
    }

    renderShare()
    {
        if (this.state.SHARE) {
            let STORY_ITEMS = [];

            return (
                <div className="form-pop-append" id="share-pop" ref={this.setShareRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <label>Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½ Ð´Ñ€ÑƒÐ³Ð°</label>
                            <InputMask placeholder="Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½ Ð´Ñ€ÑƒÐ³Ð°" type="tel" className="phone" id="share_phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
                        </div>
                        <div className="col-4 npl">
                            <label>ÐšÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ð±Ð°Ð»Ð»Ð¾Ð²</label>
                            <input name="bonuses" id="share_bonuses" type="number" className="bonuses" min="0" max={this.props.USER.BONUSES} onChange={(text) => this.recordBonuses(text)}/>
                        </div>
                        <div className="col-12">
                            <a className="orange-button" onClick={() => this.doShare()}>ÐŸÐ¾Ð´ÐµÐ»Ð¸Ñ‚ÑŒÑÑ</a>
                        </div>
                        <div className="col-12 share_message"></div>
                    </div>
                </div>
            );
        }
    }

    renderEdit(POSITION)
    {
        if (this.state.EDIT == POSITION) {
            return (
                <div className="form-pop-append" id="code-pop" ref={this.setCodeRef}>
                    <div className="form-fields">
                        <div className="col-8 npr">
                            <input name="code" id="share_bonuses" type="text" className="bonuses" onChange={(text) => this.changeCode(text)} />
                        </div>
                        <div className="col-4 npl">
                            <a className="orange-button" onClick={() => this.changeCodeNow()}>Ð¡Ð¾Ñ…Ñ€Ð°Ð½Ð¸Ñ‚ÑŒ</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderShares()
    {
        return (
            <div>

                    <div className="bonus_share_text">
                        <div>
                            ÐŸÐ¾Ð´Ð°Ñ€Ð¸Ñ‚Ðµ Ð´Ñ€ÑƒÐ³Ñƒ <strong>{this.props.USER.PROMO_DISCOUNT}</strong> ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² Ð½Ð° Ð¿ÐµÑ€Ð²ÑƒÑŽ Ð¿Ð¾ÐºÑƒÐ¿ÐºÑƒ Ð¸ Ð’Ñ‹ Ñ‚Ð°ÐºÐ¶Ðµ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚Ðµ {this.props.USER.REF_BONUS} ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² Ð½Ð° ÑÐ²Ð¾Ð¹ ÑÑ‡Ñ‘Ñ‚!
                        </div>
                        <div className="bonus-small-text">
                            Ð”Ð»Ñ ÑÑ‚Ð¾Ð³Ð¾, Ð¿Ð¾Ð´ÐµÐ»Ð¸Ñ‚ÐµÑÑŒ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´Ð¾Ð¼ ÑÐ¾ ÑÐ²Ð¾Ð¸Ð¼ Ð´Ñ€ÑƒÐ³Ð¾Ð¼ Ð½Ð° Ð¿ÐµÑ€Ð²ÑƒÑŽ Ð¿Ð¾ÐºÑƒÐ¿ÐºÑƒ Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð°Ð¹Ñ‚Ðµ Ð±Ð¾Ð½ÑƒÑÑ‹.
                        </div>
                    </div>

                    <div className="bonus-share-block">
                        <div className="bonus-title">
                            Ð’Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´
                        </div>
                        <div className="bonus-holder">
                            <div className="bonus-actions">
                                <span>
                                    {this.props.USER.CODE}
                                </span>
                                <a onClick={() => this.setState({EDIT: 'CODE'})}>
                                    <img src="/system_images/green_pencil.png" />
                                </a>
                            </div>
                        </div>

                        <div className="share_buttons">
                            <a className="share_button" onClick={() => this.setState({SHARE_CODE: true})}>
                                <img src="/system_images/green_chain.png" />
                            </a>
                            <a className="share_button" onClick={() => this.setState({SHARE_CODE: true})}>
                                <img src="/system_images/green_share.png" />
                            </a>
                            <a className="share_button" target="_blank" href={'http://api.whatsapp.com/send?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src="/system_images/green_whatsapp.png" />
                            </a>
                        </div>

                        <div className="share-holder">
                            {this.renderEdit('CODE')}
                            {this.renderShareCode()}
                        </div>
                    </div>
                    <div className="mobile_separator"></div>
                    <div className="bonus-share-block">
                        <div className="bonus-title">
                            Ð’Ð°ÑˆÐ° Ñ€ÐµÑ„ÐµÑ€Ð°Ð»ÑŒÐ½Ð°Ñ ÑÑÑ‹Ð»ÐºÐ°
                        </div>
                        <div className="bonus-holder">
                            <div className="bonus-actions">
                                <span>
                                    https://ecomarket.ru/!{this.props.USER.CODE}/
                                </span>
                                <a onClick={() => this.setState({EDIT: 'LINK'})}>
                                    <img src="/system_images/green_pencil.png" />
                                </a>
                            </div>
                        </div>

                        <div className="share_buttons">
                            <a className="share_button" onClick={() => this.setState({SHARE_LINK: true})}>
                                <img src="/system_images/green_chain.png" />
                            </a>
                            <a className="share_button" onClick={() => this.setState({SHARE_LINK: true})}>
                                <img src="/system_images/green_share.png" />
                            </a>
                            <a className="share_button" target="_blank" href={'http://api.whatsapp.com/send?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src="/system_images/green_whatsapp.png" />
                            </a>
                        </div>

                        <div className="share-holder">
                            {this.renderEdit('LINK')}
                            {this.renderShareLink()}
                        </div>
                    </div>

            </div>
        );
    }

    renderShareCode()
    {
        if (this.state.SHARE_CODE) {
            return (
                <div className="form-pop-append" ref={this.setShareCodeRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a className="cloner" onClick={() => navigator.clipboard.writeText(this.props.USER.CODE)}>
                                <i className="fas fa-clone"></i>
                            </a>
                            <a target="_blank" className="shareicon" href={'http://api.whatsapp.com/send?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://twitter.com/intent/tweet?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + this.props.USER.CODE_MESSAGE + '&description=' + this.props.USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + this.props.USER.CODE_MESSAGE + '&description=' + this.props.USER.CODE_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'viber://forward?text=' + this.props.USER.CODE_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'} />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderShareLink()
    {
        if (this.state.SHARE_LINK) {
            return (
                <div className="form-pop-append" ref={this.setShareLinkRef}>
                    <div className="share-actions">
                        <div className="sharer">
                            <a className="cloner" onClick={() => navigator.clipboard.writeText('https://ecomarket.ru/!' + this.props.USER.CODE + '/')}>
                                <i className="fas fa-clone"></i>
                            </a>
                            <a target="_blank" className="shareicon" href={'http://api.whatsapp.com/send?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/whatsapp.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://telegram.me/share/url?url=https://ecomarket.ru&text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/telegram.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://twitter.com/intent/tweet?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/twitter.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://connect.ok.ru/offer?url=https://ecomarket.ru&title=' + this.props.USER.LINK_MESSAGE + '&description=' + this.props.USER.LINK_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/ok.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://vk.com/share.php?url=https://ecomarket.ru&title=' + this.props.USER.LINK_MESSAGE + '&description=' + this.props.USER.LINK_MESSAGE + '&imageUrl=https://ecomarket.ru/imgs/logo.png'}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/vk.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'https://facebook.com/sharer.php?u=https://ecomarket.ru&quote=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/facebook.png'} />
                            </a>
                            <a target="_blank" className="shareicon" href={'viber://forward?text=' + this.props.USER.LINK_MESSAGE}>
                                <img src={CONFIG.API_C_DOMAIN + '/imgs/shares/viber.png'} />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render()
    {
        return (

            <div id="bonuses" className="inside active">
                <div className="bonusrow">
                    <div className="lk-intro-row bonus-intro-row">
                        <div>
                            <div className="on_bill">ÐÐ° Ð²Ð°ÑˆÐµÐ¼ ÑÑ‡ÐµÑ‚Ðµ <span className="green_bonuses">{this.state.BONUSES} {this.num_title(this.state.BONUSES, ['ÑÐºÐ¾Ð±Ð°Ð»Ð»', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð°', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²'])}</span></div>
                            <div className="bonus_equal">
                                1 ÑÐºÐ¾Ð±Ð°Ð»Ð» = 1 Ñ€ÑƒÐ±Ð»ÑŒ
                            </div>
                            <div className="bonus_warning">Ð­ÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹ ÑÐ³Ð¾Ñ€Ð°ÑŽÑ‚ Ð¿Ð¾ Ð¸ÑÑ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸ 30 Ð´Ð½ÐµÐ¹</div>
                        </div>

                        <div className="bonus_separator"></div>

                        <div className="bonus_navs">
                            <a className="bonus_tabled_link" onClick={() => this.setState({HISTORY: true})}>
                                Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð·Ð°Ñ‡Ð¸ÑÐ»ÐµÐ½Ð¸Ñ Ð±Ð°Ð»Ð»Ð¾Ð²
                            </a>
                            <a className="bonus_tabled_link" onClick={() => this.setState({HOW: true})}>
                                ÐšÐ°Ðº Ð·Ð°Ñ‡Ð¸ÑÐ»ÑÑŽÑ‚ÑÑ Ð±Ð°Ð»Ð»Ñ‹?
                            </a>
                            <a className="bonus_tabled_link" onClick={() => this.setState({SHARE: true})}>
                                ÐŸÐµÑ€ÐµÐ´Ð°Ñ‚ÑŒ ÑÐ²Ð¾Ð¸ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹ Ð´Ñ€ÑƒÐ³Ñƒ
                            </a>
                        </div>

                        <div className="bonus_separator"></div>

                        {this.renderShares()}

                        {this.renderHowAppend()}
                        {this.renderHistory()}
                        {this.renderShare()}
                    </div>
                </div>                
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Bonuses);