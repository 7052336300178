import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import CONFIG from './../../SYSTEM/Config.js'


function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        DO_SEARCH: state.DO_SEARCH,
        POPULAR: state.POPULAR,
        NEWPRODUCTS: state.NEWPRODUCTS,
        DISCOUNTS: state.DISCOUNTS,
        EXCLUSIVE: state.EXCLUSIVE
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Catalogue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            URL: props.url,
            CAT: false,
            ERROR: false,
            PRODUCTS: false
        }

        this.interval = false;
        this.WITH_CHILDREN = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.url !== prevProps.url) {
            this.props.closeAllModals();

            if (this.props.CATS) {
                this.setState({ CAT: false, PRODUCTS: false, URL: this.props.url, ERROR: false });
                let COMPONENT = this;
                this.interval = setInterval(function () {
                    COMPONENT.prepareData()
                }, 50);
            }
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    componentDidMount() {
        this.props.closeAllModals();

        let COMPONENT = this;
        this.interval = setInterval(function () {
            COMPONENT.prepareData()
        }, 50);
    }

    getCat = () => {
        let CAT = false;
        let NEXT = false;
        let PREV = false;
        let SET_J = false;
        let SET_I = false;

        if ((this.props.url !== "popular") && (this.props.url !== "newproducts") && (this.props.url !== "exclusive") && (this.props.url !== "discounts")) {
            if (this.props.CATS) {
                for (let i = 0; i < this.props.CATS.length; i++) {
                    if ((!CAT) || (SET_I + 1 === i)) {

                        let PARENT_CAT = this.props.CATS[i];
                        if (PARENT_CAT.url === this.state.URL) {
                            CAT = PARENT_CAT;
                            SET_I = i;

                            if (SET_I + 1 < this.props.CATS.length) {
                                NEXT = this.props.CATS[SET_I + 1];
                            } else {
                                NEXT = this.props.CATS[0];
                            }
                            if (SET_I - 1 > 0) {
                                PREV = this.props.CATS[SET_I - 1];
                            } else {
                                PREV = this.props.CATS[this.props.CATS.length - 1];
                            }
                        }
                    }
                }
            }
            if (CAT) {
                CAT.NEXT = NEXT;
                CAT.PREV = PREV;
            }
        } else {
            if (this.props.url == 'popular') {
                CAT = {
                    title: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾',
                    children: false,
                    id: 0,
                    url: 'popular',
                    parent: {},
                    meta_title: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾',
                    meta_keywords: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾',
                    meta_description: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾'
                };
            } else if (this.props.url == 'exclusive') {
                CAT = {
                    title: 'Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²',
                    children: false,
                    id: -1,
                    url: 'exclusive',
                    parent: {},
                    meta_title: 'Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²',
                    meta_keywords: 'Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²',
                    meta_description: 'Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²'
                };
            } else if (this.props.url == 'newproducts') {
                CAT = {
                    title: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸',
                    children: false,
                    id: -2,
                    url: 'newproducts',
                    parent: {},
                    meta_title: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸',
                    meta_keywords: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸',
                    meta_description: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸'
                };
            } else if (this.props.url == 'discounts') {
                CAT = {
                    title: 'Ð’Ñ‹Ð³Ð¾Ð´Ð½Ñ‹Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸Ñ',
                    children: false,
                    id: -3,
                    url: 'discounts',
                    parent: {},
                    meta_title: 'Ð’Ñ‹Ð³Ð¾Ð´Ð½Ñ‹Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸Ñ',
                    meta_keywords: 'Ð’Ñ‹Ð³Ð¾Ð´Ð½Ñ‹Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸Ñ',
                    meta_description: 'Ð’Ñ‹Ð³Ð¾Ð´Ð½Ñ‹Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸Ñ'
                };
            }
        }

        if (CAT) {
            this.setState({ CAT: CAT, ERROR: false });
        } else {
            clearInterval(this.interval);
            this.setState({ CAT: 'NONE', PRODUCTS: true, ERROR: true });
        }
    }

    prepareData = async () => {
        if (!this.state.CAT) {
            if (this.props.CATS) {
                this.getCat();
            }
        }

        if (this.state.CAT) {
            if (!this.state.PRODUCTS) {
                let PRODUCTS = this.getProducts(this.state.CAT);
                this.setState({ PRODUCTS: PRODUCTS });
                clearInterval(this.interval);
            }
        }
    }

    getProducts(CAT) {
        let GROUPED_PRODUCTS = this.props.GROUPED_PRODUCTS;

        if ((CAT.children) && (CAT.children.length)) {

            this.setState({ WITH_CHILDREN: true });

            let GROUPS = [];

            for (let i in CAT.children) {
                let ID = CAT.children[i].id;

                if (typeof GROUPED_PRODUCTS[ID] !== "undefined") {
                    if (GROUPED_PRODUCTS[ID].length > 0) {
                        GROUPS.push(
                            <LazyLoad key={ i } height={ 300 }>
                                <ProductsScrollPreview CAT={ CAT.children[i] } IDS={ GROUPED_PRODUCTS[ID] } />
                            </LazyLoad>
                        );
                    }
                }
            }

            return (
                <div>
                    { GROUPS }
                </div>
            );

        } else {
            let USE_PRODUCTS = false;
            this.setState({ WITH_CHILDREN: false });

            if (CAT.id > 0) {
                if (typeof GROUPED_PRODUCTS[CAT.id] !== "undefined") {
                    USE_PRODUCTS = GROUPED_PRODUCTS[CAT.id];
                }
            } else {
                if (CAT.id == 0) {
                    if (typeof this.props.POPULAR !== "undefined") {
                        USE_PRODUCTS = this.props.POPULAR;
                    }
                } else if (CAT.id == -1) {
                    if (typeof this.props.EXCLUSIVE !== "undefined") {
                        USE_PRODUCTS = this.props.EXCLUSIVE;
                    }
                } else if (CAT.id == -2) {
                    if (typeof this.props.NEWPRODUCTS !== "undefined") {
                        USE_PRODUCTS = this.props.NEWPRODUCTS;
                    }
                } else if (CAT.id == -3) {
                    if (typeof this.props.DISCOUNTS !== "undefined") {
                        USE_PRODUCTS = this.props.DISCOUNTS;
                    }
                }
            }

            let CLASS = 'card_holder ' + this.getItemsClass();

            if (USE_PRODUCTS) {
                return (
                    <div className="products_grid">
                        { USE_PRODUCTS.map(id => <div className={ CLASS } key={ id }><ProductCard ID={ id } /></div>) }
                    </div>
                );
            }
        }

        return false;
    }

    getItemsClass() {
        return '';
    }

    renderH1() {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        { this.state.CAT.title }
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });
        if (typeof this.state.CAT.parent.title !== "undefined") {
            DATA.push({
                'to': '/' + this.state.CAT.parent.url,
                'title': this.state.CAT.parent.title
            });
        }
        DATA.push({
            'to': '/' + this.state.CAT.url,
            'title': this.state.CAT.title
        });

        return <BreadCrumbs DATA={ DATA } />
    }

    renderNextCat() {

        if (this.state.CAT.NEXT) {

            return (
                <div className="main_more_holder">
                    <Link to={ '/' + this.state.CAT.NEXT.url } className="main_more cat_more">
                        <span>{ this.state.CAT.NEXT.title }</span><img src="/system_images/angle_right.svg" />
                    </Link>
                </div>
            );
        } else {
            console.log(this.state.CAT.id)
            if (this.state.CAT.id == -3) {
                return (
                    <div className="main_more_holder">
                        <Link to={ '/newproducts' } className="main_more cat_more">
                            <span>ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸</span><img src="/system_images/angle_right.svg" />
                        </Link>
                    </div>
                );
            } else if (this.state.CAT.id == -2) {
                return (
                    <div className="main_more_holder">
                        <Link to={ '/exclusive' } className="main_more cat_more">
                            <span>Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²</span><img src="/system_images/angle_right.svg" />
                        </Link>
                    </div>
                );
            } else if (this.state.CAT.id == -1) {
                return (
                    <div className="main_more_holder">
                        <Link to={ '/hleb-vyp-snek' } className="main_more cat_more">
                            <span>Ð¥Ð»ÐµÐ±</span><img src="/system_images/angle_right.svg" />
                        </Link>
                    </div>
                );
            }
        }
    }

    renderPrevCat() {
        return false;

        if (this.state.CAT.PREV) {
            let CAT = this.state.CAT.PREV;
            let CAT2 = this.state.CAT.NEXT;
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="cats-preview">
                            <div className="preview-col-6">
                                <Link to={ "/" + CAT.url } className="cat_preview big" style={ { backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT.image + ')' } }>
                                    <div className="more-intro">
                                        ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ð² Ð¿Ñ€ÐµÐ´Ñ‹Ð´ÑƒÑ‰Ð¸Ð¹ Ñ€Ð°Ð·Ð´ÐµÐ»
                                    </div>
                                    <span>{ CAT.title }</span>
                                </Link>
                            </div>
                            <div className="preview-col-6">
                                <Link to={ "/" + CAT2.url } className="cat_preview big" style={ { backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT2.image + ')' } }>
                                    <div className="more-intro">
                                        ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ð² ÑÐ»ÐµÐ´ÑƒÑŽÑ‰Ð¸Ð¹ Ñ€Ð°Ð·Ð´ÐµÐ»
                                    </div>
                                    <span>{ CAT2.title }</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderHiddenText() {
        if (this.state.CAT.after !== '') {
            return (
                <div>
                    <div dangerouslySetInnerHTML={ { __html: this.state.CAT.after } }>
                    </div>
                </div>
            );
        }
    }

    render() {
        if ((!this.state.CAT) && (!this.state.PRODUCTS)) {
            return <InnerLoader />
        }

        if (this.state.ERROR) {
            return <E404 />;
        }

        if (this.state.WITH_CHILDREN) {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part transparent">
                        <Helmet>
                            <title>{ this.state.CAT.meta_title }</title>
                            <meta name="description" content={ this.state.CAT.meta_description } />
                            <meta name="keywords" content={ this.state.CAT.meta_keywords } />
                        </Helmet>
                        <div id="page-content" className="container">
                            <div className="go_back_btn">
                                <Link to="/" className="return_button">
                                    <img src="/system_images/a_l.png" />
                                </Link>
                            </div>
                            <div>
                                { this.renderH1() }
                            </div>
                            { this.state.PRODUCTS }
                            { this.renderPrevCat() }
                            { this.renderNextCat() }
                        </div>


                        { this.renderHiddenText() }

                        <ModalsContainer />
                    </div>
                </CSSTransitionGroup>
            );
        } else {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part">
                        <Helmet>
                            <title>{ this.state.CAT.meta_title }</title>
                            <meta name="description" content={ this.state.CAT.meta_description } />
                            <meta name="keywords" content={ this.state.CAT.meta_keywords } />
                        </Helmet>
                        <div id="page-content" className="container">
                            <div className="go_back_btn">
                                <Link to="/" className="return_button">
                                    <img src="/system_images/a_l.png" />
                                </Link>
                            </div>
                            <div>
                                { this.renderH1() }
                            </div>
                            { this.state.PRODUCTS }
                            { this.renderNextCat() }
                        </div>

                        <ModalsContainer />
                    </div>
                </CSSTransitionGroup>
            );
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Catalogue))