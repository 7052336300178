import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CONFIG from './../../../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import MainNavs from './../../../../APP/COMPONENTS/HeaderParts/NavBar/MainNavs';

function mapStateToProps(state, myProps) {
    return {
        CATS: state.CATS,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class CatNavs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ACTIVE: false,
            SHOW_CAT: false
        }

        this.setNavRef = this.setNavRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    setNavRef(node) {
        this.nav = node;
    }

    handleClickOutside(event) {
        if (this.nav && !this.nav.contains(event.target)) {
            if (this.state.SHOW_CAT) {
                this.setState({ SHOW_CAT: false });
            }
        }
    }

    checkActiveArrow = (id) => {
        if (this.state.ACTIVE == id) {
            return 'nav-load-more';
        } else {
            return 'nav-load-more';
        }
    }

    renderSubCatsArrow = (CAT) => {
        let CLASSES = this.checkActiveArrow(CAT.id);

        return (
            <div className={ CLASSES }>
                <i className="fa fa-angle-right"></i>
            </div>
        );
    }

    renderNavIcon = (CAT) => {
        let IMAGE = <div className="nav-image"></div>;

        if (CAT.ico !== "") {
            let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/groups/icos/' + CAT.ico;
            IMAGE = (
                <div className="nav-image">
                    <img src={ IMAGE_SRC } />
                </div>
            );
        }

        return IMAGE;
    }

    checkActive = (id) => {
        if (this.state.ACTIVE == id) {
            return 'foldable selected';
        } else {
            return 'foldable';
        }
    }

    checkSubActive = (id) => {
        if (this.state.ACTIVE == id) {
            return true;
        } else {
            return false;
        }
    }

    renderSubCats = (CAT) => {
        let SUB_ITEMS = false;

        let CLASS = "in-sub-menu";
        if (this.checkSubActive(CAT.id)) {
            CLASS = "in-sub-menu visible";
        }

        SUB_ITEMS = [];
        for (let i = 0; i < CAT.children.length; i++) {
            let SUB_CAT = CAT.children[i];
            SUB_ITEMS.push(this.renderSubLi(SUB_CAT, i));
        }
        SUB_ITEMS = (
            <ul className={ CLASS } key="1">
                { SUB_ITEMS }
            </ul>
        );

        return (
            <CSSTransitionGroup
                transitionName="slide"
                transitionEnterTimeout={ 300 }
                transitionLeaveTimeout={ 300 }
            >
                { SUB_ITEMS }
            </CSSTransitionGroup>
        );
    }

    renderSubLi = (CAT, i) => {
        return (
            <li
                key={ i }
            >
                <Link to={ '/' + CAT.url }
                    href={ CAT.url }
                    title={ CAT.title }
                    onClick={ () => this.setState({ SHOW_CAT: false }) }
                >
                    { CAT.title }
                </Link>
            </li>
        );
    }

    toggleSubs = (id) => {
        let SET = id;
        if (this.state.ACTIVE == id) {
            SET = false;
        }

        this.setState({ ACTIVE: SET });
    }

    renderLi = (CAT, i) => {
        let IMAGE = this.renderNavIcon(CAT);

        if (CAT.children.length) {
            let CLASSES = this.checkActive(CAT.id);
            let SUB_CATS_ARROW = this.renderSubCatsArrow(CAT);
            let SUB_CATS = this.renderSubCats(CAT);

            return (
                <li
                    key={ i }
                >
                    <a
                        className={ CLASSES }
                        title={ CAT.title }
                        onClick={ () => this.toggleSubs(CAT.id) }
                    >
                        { IMAGE }
                        { CAT.title }
                        { SUB_CATS_ARROW }
                    </a>
                    { SUB_CATS }
                </li>
            );
        } else {
            return (
                <li
                    key={ i }
                >
                    <Link to={ '/' + CAT.url }
                        onClick={ () => this.hideNavs() }
                        title={ CAT.title }
                    >
                        { IMAGE }
                        { CAT.title }
                    </Link>
                </li>
            );
        }
    }

    getClass = () => {
        if (this.state.SHOW_CAT) {
            return 'drop-cats visible';
        } else {
            return 'drop-cats';
        }
    }

    renderNavImage = () => {
        if (WIDTH > 760) {
            return <img src="/system_images/menu.png" />;
        }


        if (this.state.SHOW_CAT) {
            return <img src="/system_images/close-menu.png" />;
        } else {
            return <img src="/system_images/menu.png" />;
        }
    }

    toggleNav = () => {
        let SET = true;
        if (this.state.SHOW_CAT) {
            SET = false;
        }

        this.setState({ SHOW_CAT: SET });
    }

    hideNavs = () => {

        this.setState({ SHOW_CAT: true });
        if (typeof this.props.hideNavs !== "undefined") {
            this.props.hideNavs();
        }
    }

    renderStaticLinks = () => {
        return (
            <>
                {/* <li
                    key={-3}
                >
                    <Link to="/discounts"
                        onClick={() => this.hideNavs()}
                        title="Ð’Ñ‹Ð³Ð¾Ð´Ð½Ñ‹Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸Ñ"
                    >
                        <div className="nav-image">
                            <img src="/system_images/icons/discount.svg" />
                        </div>
                        Ð’Ñ‹Ð³Ð¾Ð´Ð½Ñ‹Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸Ñ
                    </Link>
                </li>

                <li
                    key={-2}
                >
                    <Link to="/newproducts"
                        onClick={() => this.hideNavs()}
                        title="ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸"
                    >
                        <div className="nav-image">
                            <img src="/system_images/icons/new.svg" />
                        </div>
                        ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸
                    </Link>
                </li>

                <li
                    key={-1}
                >
                    <Link to="/exclusive"
                        onClick={() => this.hideNavs()}
                        title="Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²"
                    >
                        <div className="nav-image">
                            <img src="/system_images/icons/exclusive.svg" />
                        </div>
                        Ð­ÐºÑÐºÐ»ÑŽÐ·Ð¸Ð²
                    </Link>
                </li> */}

            </>
        )
    }

    render() {
        let CATS = this.props.CATS;

        if (CATS) {
            let ITEMS = [];

            for (let i = 0; i < CATS.length; i++) {
                let CAT = CATS[i];
                ITEMS.push(this.renderLi(CAT, i));
            }

            return (
                <div className="cat_holder" ref={ this.setNavRef } >
                    <div className={ this.getClass() }>
                        { this.renderStaticLinks() }
                        { ITEMS }
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CatNavs)
