import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import LogoFeet from './../../APP/COMPONENTS/HeaderParts/LogoFeet';
import MainNavs from './../../APP/COMPONENTS/HeaderParts/NavBar/MainNavs';
import CatNavs from './../../APP/COMPONENTS/HeaderParts/NavBar/CatNavs';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class footer extends Component {

	render() {
		return (
			<section id="footer">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="foot-flex">
								<div>
									<Link to="/" className="logo_block">
										<img src="/system_images/logo.svg" id="logo" />
									</Link>
									<div className="footer_social">
										<a href="https://wa.me/79854477085" target="_blank"><img src="/system_images/social/whatsapp.png" alt="ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ð¥Ð»ÐµÐ± Ð² WhatsApp" /></a>
										<a href="https://vk.com/public211458287" target="_blank"><img src="/system_images/social/vk.png" alt="ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ð¥Ð»ÐµÐ± Ð² Vkontakte" /></a>
										<a href="https://t.me/79854477085" target="_blank"><img src="/system_images/social/telegram.png" alt="ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹ Ð¥Ð»ÐµÐ± Ð² Telegram" /></a>
									</div>
									<a className="footer_phone" href="tel: +7 985 447-70-85">ÐŸÐ¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚ÑŒ Ð½Ð°Ð¼</a>
								</div>
								<div className="footer_navs">
								<Link to="/company/delivery" onClick={() => {this.props.showNavs(false);document.body.classList.remove("modal-open");}}>
									Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°
								</Link>
								<Link to="/company/info" onClick={() => {this.props.showNavs(false);document.body.classList.remove("modal-open");}}>
									Ðž Ð½Ð°Ñ
								</Link>
								<Link to="/company/partnership" onClick={() => {this.props.showNavs(false);document.body.classList.remove("modal-open");}}>
									Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾
								</Link>
								<Link to="/company/contacts" onClick={() => {this.props.showNavs(false);document.body.classList.remove("modal-open");}}>
									ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹
								</Link>
				                </div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(footer)
