import React, { Component } from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import LazyLoad from 'react-lazyload';
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        DO_SEARCH: state.DO_SEARCH
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            FOUND: false,
            SEARCH_STRING: ''
        }
    }

    componentDidMount() {
        this.props.closeAllModals();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.filterProducts(this.props.DO_SEARCH);
            }
        }
    }

    filterProducts_OLD = async (text) => {
        if (this.props.PRODUCTS) {
            try {
                let SEND_DATA = {
                    action: 'searchString',
                    string: text
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    if (data.results.length) {
                        this.setSearchResults(data.results, text);
                    } else {
                        this.setState({ FOUND: false });
                    }
                } else {
                    this.setState({ FOUND: false });
                }
            } catch (error) {
                this.setState({ FOUND: false });
            }

        }
    }

    setSearchResults(IDS, text) {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        let MAX = 10;
        for (let i = 0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                FOUND.push(ID);
            }
        }


        this.setState({ FOUND: FOUND });
    }

    filterProducts = (text) => {
        if (text == 'on') {
            this.setState({ FOUND: false });
        } else {
            let PRODUCTS = this.props.PRODUCTS;
            let FOUND = [];
            let COMPONENT = this;

            if (this.props.PRODUCTS) {
                Object.keys(PRODUCTS).forEach(key => {
                    let PRODUCT = PRODUCTS[key];

                    const itemData = PRODUCT.title.toUpperCase();
                    const textData = text.toUpperCase();
                    if (itemData.indexOf(textData) > -1) {
                        FOUND.push(PRODUCT.id);
                    }
                });
            }

            this.setState({ FOUND: FOUND });
            if (this.props.PRODUCTS) {
                if (this.interval) {
                    clearInterval(this.interval);
                }
            }
        }
    }

    getItemsClass() {
        let WIDTH = window.innerWidth;
        if (WIDTH < 300) {
            return 'col-12';
        } else if (WIDTH < 769) {
            return 'col-6';
        } else if (WIDTH < 1050) {
            return 'col-4';
        } else if (WIDTH < 1200) {
            return 'col-3';
        } else if (WIDTH < 9900) {
            return 'el-5';
        }
    }

    renderH1() {
        if (this.state.STRING !== 'on') {
            return (
                <div className="row">
                    <div className="col-12">
                        <h1 className="catalog__title">
                            ÐŸÐ¾Ð¸ÑÐº: { this.state.STRING }
                        </h1>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                        <h1 className="catalog__title">
                            ÐŸÐ¾Ð¸ÑÐº
                        </h1>
                    </div>
                </div>
            );
        }

    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });
        DATA.push({
            'to': '/',
            'title': 'ÐŸÐ¾Ð¸ÑÐº'
        });

        return <BreadCrumbs DATA={ DATA } />
    }

    renderProducts() {
        let CLASS = 'card_holder';

        if (this.state.FOUND) {
            return (
                <div className="products_grid">
                    { this.state.FOUND.map(id => <div className={ CLASS } key={ id }><LazyLoad height={ 300 }><ProductCard ID={ id } /></LazyLoad></div>) }
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                    </div>
                </div>
            );
        }
    }

    checkRerender() {
        if (this.state.STRING !== this.props.match.params.string) {
            if (this.props.match.params.string !== 'on') {
                this.filterProducts();
            }
        }
    }

    startSearch() {
        if (this.state.SEARCH_STRING.length > 2) {
            this.props.doSearch(this.state.SEARCH_STRING);
        }
    }

    somethingInputted = (string) => {
        let STRING = string.target.value;
        this.setState({ SEARCH_STRING: STRING });
    }

    handleSearch = (e) => {
        if (e.key === 'Enter') {
            this.startSearch()
        }
    }

    renderSearchForm() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="search_page_form">
                        <div className="searching">
                            <input
                                onKeyDown={ this.handleSearch }
                                className="searchinput"
                                type="text"
                                placeholder="ÐŸÐ¾Ð¸ÑÐº"
                                onChange={ (text) => this.somethingInputted(text) }
                                value={ this.state.SEARCH_STRING }
                            />
                            <a className="search-btn" onClick={ () => this.startSearch() }><i className="fas fa-search"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        ///this.checkRerender();
        return (
            <CSSTransitionGroup
                transitionAppear={ true }
                transitionAppearTimeout={ 600 }
                transitionEnterTimeout={ 600 }
                transitionLeaveTimeout={ 200 }
                transitionName={ 'SlideIn' }
            >
                <div className="right-part">
                    <Helmet>
                        <title>ÐŸÐ¾Ð¸ÑÐº</title>
                    </Helmet>
                    <div className="go_back_btn">
                        <Link to="/" className="return_button">
                            <img src="/system_images/a_l.png" />
                        </Link>
                    </div>
                    <div id="page-content" className="container">
                        <div>
                            { this.renderH1() }
                        </div>

                        { this.renderProducts() }
                    </div>

                    <ModalsContainer />
                </div>
            </CSSTransitionGroup>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Search))