import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import HomePage from './../../APP/SCENES/HomePage';
import ShopEnter from './../../APP/SCENES/ShopEnter';
import Company from './../../APP/SCENES/Company';
import Search from './../../APP/SCENES/Search';
import Catalog from './../../APP/SCENES/Catalog';
import PersonalPage from './../../APP/SCENES/PersonalPage';
import Partnership from './../../APP/SCENES/STATIC_PAGES/Partnership';
import Contacts from './../../APP/SCENES/STATIC_PAGES/Contacts';
import SiteMap from './../../APP/SCENES/STATIC_PAGES/SiteMap';
import NavBar from './../../APP/COMPONENTS/HeaderParts/NavBar';
import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class content extends Component {

	render() {
		return (
			<>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="real_page">
							<NavBar />
							<div className="main_page_holder">
								<Switch>
									<Route exact path='/' component={HomePage}/>
									<Route exact path='/personal' component={PersonalPage}/>
									<Route exact path='/company/partnership' component={Partnership}/>
									<Route exact path='/company/contacts' component={Contacts}/>
									<Route exact path='/company/sitemap' component={SiteMap}/>
									<Route exact path='/company/:url' component={Company}/>
									<Route path='/search' component={Search}/>	
									<Route path='/catalog' component={Catalog}/>			
									<Route path='/:url' component={ShopEnter}/>			
								</Switch>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<FooterBlock />
			</>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(content)
