import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import LazyLoad from 'react-lazyload';

import Slider from "react-slick";
import smoothscroll from 'smoothscroll-polyfill';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductsStaticPreview from './../../../APP/COMPONENTS/ContentParts/ProductsStaticPreview';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        REGION: state.REGION,
        TOKEN: state.TOKEN,
        PREPARED_CATS: state.PREPARED_CATS,
        POPULAR: state.POPULAR
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

class CatPreview extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);

        this.state = {
            REGION: props.REGION,
            CATS: props.CATS,
            PREPARED_CATS: [],
            slideIndex: 0,
            updateCount: 0
        };

        this.ALLIDS = [];
        this.LOADING = false;
        this.LOADED = {};
        this.elements = [];
        this.TAP_NUMBER = 0;
        this.TAP_TO_SCROlL = false;
    }

    componentDidMount() {
        this.getCatsAndIds();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.REGION !== prevProps.REGION)) {
            this.LOADING = false;
            this.LOADED = {};
            this.setState({ CATS: this.props.CATS, REGION: this.props.REGION, PREPARED_CATS: [] });
            this.getCatsAndIds();
        }

    }

    getCatsAndIds = async () => {
        if (this.state.PREPARED_CATS.length == 0) {
            if (!this.LOADING) {
                this.LOADING = true;
                if (this.state.CATS) {

                    for (let i = 0; i < this.props.CATS.length; i++) {

                        let CAT = this.state.CATS[i];

                        if (typeof CAT !== "undefined") {
                            if (!this.LOADED[CAT.id]) {
                                let products = await this.getCatData(this.props.CATS[i]);
                                if (products) {
                                    if (products.length) {
                                        this.setState({ PREPARED_CATS: this.state.PREPARED_CATS.concat({ CAT: this.props.CATS[i], IDS: products }) })
                                        this.LOADED[CAT.id] = true;
                                    }
                                }
                            }
                        }
                    }

                    this.props.setPreparedCats(this.state.PREPARED_CATS);
                }
            }
        }
    }


    getCatData = async (CAT) => {
        try {
            let SEND_DATA = {
                action: 'getCarouselForCat',
                id: CAT.id,
                REGION: this.state.REGION,
                AB_CASE: window.AB_CASE,
                token: this.props.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                return data;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    renderRows = () => {
        this.getCatsAndIds();

        if (this.state.PREPARED_CATS) {
            let RENDERED_CATS = [];

            for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
                let OBJ = this.state.PREPARED_CATS[i];
                this.elements[OBJ.CAT.url] = React.createRef();
                RENDERED_CATS.push(<ProductsScrollPreview key={ i } ref={ this.elements[OBJ.CAT.url] } id={ OBJ.CAT.id } CAT={ OBJ.CAT } IDS={ OBJ.IDS } />)
            }

            return (
                <div>
                    { RENDERED_CATS.map(carousel => carousel) }
                </div>
            );
        }
    }

    handelScroll = (event) => {
        let slideId = 0


        if (!this.TAP_TO_SCROlL) {

            for (let i = 0; i < this.ALLIDS.length; i++) {
                let div = document.getElementById(this.ALLIDS[i])
                let bounding = div.getBoundingClientRect();

                if (
                    bounding.top <= 150
                    // bounding.left >= 0 &&
                    // bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                    // bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight )
                ) {
                    for (let j = 0; j < this.ALLIDS.length; j++) {
                        let div = document.getElementById(this.ALLIDS[j])

                        document.getElementById(this.ALLIDS[j]).className = "scroll_margin"

                        document.getElementById(parseInt(this.ALLIDS[j]) + 'slide').style.background = "#f3f3f7"

                    }

                    // document.getElementById(this.ALLIDS[i]).style.background = "#faebd791"
                    document.getElementById(parseInt(this.ALLIDS[i]) + 'slide').style.background = "rgb(197 156 104 / 51%)"
                    slideId = i
                    this.TAP_NUMBER = 2;


                } else {

                    //document.getElementById(this.ALLIDS[i]).style.background = "#fff"
                    document.getElementById(parseInt(this.ALLIDS[i]) + 'slide').style.background = "#f3f3f7"

                }
            }
            if (window.innerWidth < 700) {
                this.slider.slickGoTo(slideId)
            }

        }

        if (window.innerWidth <= 1200) {


            if (window.scrollY >= this.offset) {
                if (!this.state.TAGS_CONTAINER_FIXED) {
                    this.setState({ TAGS_CONTAINER_FIXED: true })
                }
            } else {
                if (this.state.TAGS_CONTAINER_FIXED) {
                    this.setState({ TAGS_CONTAINER_FIXED: false })
                }
            }
        }
    }

    executeScroll = (element_id, allIds) => {
        this.TAP_TO_SCROlL = true

        smoothscroll.polyfill(); //Ð½ÑƒÐ¶Ð½Ð¾ Ð´Ð»Ñ Ñ€Ð°Ð±Ð¾Ñ‚Ñ‹ scrollIntoView behavior: "smooth" Ð² SAFARI

        if (this.TAP_TO_SCROlL) {
            this.TAP_NUMBER = this.TAP_NUMBER + 1
            if (typeof allIds !== "undefined") {
                for (let i = 0; i < allIds.length; i++) {
                    document.getElementById(allIds[i]).className = "scroll_margin"
                    document.getElementById(parseInt(this.ALLIDS[i]) + 'slide').style.background = "#FFFFFF"
                }
                let hiddenElement = document.getElementById(element_id)
                hiddenElement.scrollIntoView({ block: "start", behavior: "smooth" })
                if (this.TAP_NUMBER === 1) {
                    document.getElementById(parseInt(element_id) + 'slide').style.background = "#F5EBD6"
                    hiddenElement.className = "first_selected_tag"

                } else {
                    document.getElementById(parseInt(element_id) + 'slide').style.background = "#F5EBD6"
                    hiddenElement.className = "selected_tag"
                }
            }

            setTimeout(() => {
                this.TAP_TO_SCROlL = false
            }, 800)


        }

    }

    renterTagsToSlides = () => {

        let SLIDES = [];
        let ALL_IDS = []

        for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
            let OBJ = this.state.PREPARED_CATS[i];
            ALL_IDS.push(OBJ.CAT.id)
        }
        this.ALLIDS = ALL_IDS

        if (this.state.PREPARED_CATS) {
            for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
                let OBJ = this.state.PREPARED_CATS[i];

                SLIDES.push(
                    <div id={ parseInt(OBJ.CAT.id) + 'slide' } className='tags_slider_button'
                        key={ i }
                        onClick={ () => this.executeScroll(OBJ.CAT.id, ALL_IDS) }>
                        { OBJ.CAT.title }
                    </div>
                );
            }
        }

        if (SLIDES.length === this.state.PREPARED_CATS.length) {
            return SLIDES
        }
    }

    renderSlider = () => {
        let settings = {
            // infinite: window.innerWidth < 1000,
            speed: 100,
            slidesToShow: window.innerWidth < 1000 ? 1 : 12,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            swipeToSlide: true,
            infinite: false,
            focusOnSelect: false,
        };

        return (
            <div>
                <Slider ref={ slider => (this.slider = slider) } { ...settings }>
                    { this.renterTagsToSlides() }
                </Slider>
            </div>
        );
    }

    renderTagsSlider = () => {
        return (
            <div>
                <div id="main_tags_slider" className={ this.state.TAGS_CONTAINER_FIXED
                    ? "main_tags_slider fixed"
                    : "main_tags_slider" }>
                    { this.renderSlider() }
                </div>
            </div>

        )
    }

    render() {
        return (
            <div>
                { this.renderRows() }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CatPreview);