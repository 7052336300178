import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Login extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        	PHONE: '+7-___-___-__-__',
        	CODE: '____',
        	PASSWORD: '',
        	VIEW: 'LOGIN',
        	SENT: false,
        	TOKEN: this.props.TOKEN,
        	SECONDS: 60
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.TOKEN !== prevProps.TOKEN) {
            this.setState({TOKEN: this.props.TOKEN});
        }
    }

    recordPhone = (phone) => 
    {
    	this.setState({PHONE: phone.target.value});
    }

    recordCode = (code) => 
    {
    	this.setState({CODE: code.target.value});
    }

    recordPassword = (password) => 
    {
    	this.setState({PASSWORD: password.target.value});
    }

    doConfirm = async() =>
    {
    	try {
	    	let SEND_DATA = {
	            action:   	'doConfirm',
	            token: 		this.state.TOKEN,
	            phone: 		this.state.PHONE,
	            code: 	this.state.CODE
	        };

	        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
	        if (data !== false) {
	        	this.props.doAuth(data.user);
	        }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }
    
    doLogin = async() =>
    {
    	try {
	    	let SEND_DATA = {
	            action:   	'doLogin',
	            token: 		this.state.TOKEN,
	            phone: 		this.state.PHONE,
	            password: 	this.state.PASSWORD
	        };

	        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
	        if (data !== false) {
	        	this.props.doAuth(data.user);
	        }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    doRegister()
    {
    	this.setState({VIEW: 'REGISTER'})
    }

    doGetCode = async() =>
    {
    	let SEND_DATA = {
            action:     'doRegisterOrRecover',
            phone:      this.state.PHONE,
            token:      this.state.TOKEN
        };

        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

        if (data !== false) {
        	this.setState({VIEW: 'CONFIRM', SENT: true, SECONDS: 20})
            this.countDown();
        }
    }

    countDown()
	{
		let COMPONENT = this;
	    this.COUNTER = setInterval(function() {
	    	let SECONDS = COMPONENT.state.SECONDS;

	            if (SECONDS > 0) {
	                SECONDS--;
	                COMPONENT.setState({SECONDS: SECONDS});
	            } else {
	                clearInterval(COMPONENT.COUNTER);
	                COMPONENT.setState({SECONDS: 0, SENT: false});
	            }
	        }, 1000);
	}

	handleLogin = (e) => {
		if (e.key === 'Enter') {
			this.doLogin()
		}
	}

	handleRegister = (e) => {
		if (e.key === 'Enter') {
			this.doGetCode()
		}
	}

	handleConfirm = (e) => {
		if (e.key === 'Enter') {
			this.doConfirm()
		}
	}

	getLoginButton()
    {
    	let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if ((phoneNumber.length == 11) && (this.state.PASSWORD.length > 3)) {
        	return (
        		<button className="orange-button" onClick={() => this.doLogin()}>
					Ð’Ð¾Ð¹Ñ‚Ð¸
				</button>
        	);
        } else {
            return (
            	<button className="orange-button inactive-btn">
					Ð’Ð¾Ð¹Ñ‚Ð¸
				</button>
        	);
        }
    }

    

    getRegisterButton()
    {
    	let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
        	return (
        		<button className="orange-button" onClick={() => this.doGetCode()}>
						ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ ÐºÐ¾Ð´
				</button>
        	);
        } else {
            return (
            	<button className="orange-button inactive-btn">
						ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ ÐºÐ¾Ð´
				</button>
        	);
        }
    }

    renderLogin()
    {
    	return (
    		<div className="login_form_block form-fields">
	    		<div className="col-12 flexed-col">
	    			<span className="in-card-label">
	    				Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° Ð¸ Ð¿Ð°Ñ€Ð¾Ð»ÑŒ Ð´Ð»Ñ Ð²Ñ…Ð¾Ð´Ð° Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚
					</span>
				</div>

				<div className="col-12 flexed-col">
					<label>Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½</label>
					<InputMask className="ui_input requiredinput" onKeyDown={this.handleLogin} placeholder="Ð’Ð°Ñˆ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½" type="tel" className="phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
				</div>

				<div className="col-12 flexed-col">
					<label>ÐŸÐ°Ñ€Ð¾Ð»ÑŒ</label>
					<input className="ui_input requiredinput" onKeyDown={this.handleLogin} type="password" name="password" placeholder="Ð’Ð°Ñˆ Ð¿Ð°Ñ€Ð¾Ð»ÑŒ" onChange={(text) => this.recordPassword(text)} required="" />
				</div>

				<div className="col-12 flexed-col">
					{this.getLoginButton()}
				</div>

				<div className="col-12 flexed-col">
					<a className="dont-remember" onClick={() => this.doRegister()}>ÐÐµ Ð¿Ð¾Ð¼Ð½ÑŽ Ð¿Ð°Ñ€Ð¾Ð»ÑŒ</a>
					<a className="register-button" onClick={() => this.doRegister()}>
						Ð ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ñ
					</a>
				</div>
			</div>
		);
    }

    renderRegister()
    {
    	return (
    		<div className="login_form_block form-fields">
    			<div className="col-12 flexed-col">
	    			<span className="in-card-label">
    					Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°, Ð½Ð° ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð¼Ñ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð¼ Ð²Ð°Ð¼ ÐºÐ¾Ð´ Ð´Ð»Ñ Ð²Ñ…Ð¾Ð´Ð° Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚.
					</span>
				</div>

				<div className="col-12 flexed-col">
					<label>Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½</label>
					<InputMask onKeyDown={this.handleRegister} placeholder="Ð’Ð°Ñˆ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½" type="tel" className="phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} />
				</div>

				<div className="col-12 flexed-col">
					{this.getRegisterButton()}
				</div>
			</div>
		);
    }

    renderConfirmButton()
    {
    	let code = this.state.CODE.toString().replace(/\D+/g, '');

    	if (code.length < 4) {
    		return <button className="orange-button inactive-btn">Ð’Ð¾Ð¹Ñ‚Ð¸</button>
    	} else {
    		return <button className="orange-button" onClick={() => this.doConfirm()}>Ð’Ð¾Ð¹Ñ‚Ð¸</button>
    	}
    }

    renderConfirm()
    {
    	return (
	    	<div className="login_form_block form-fields">
	    			<div className="col-12 flexed-col">
	    				<span className="in-card-label">
	    					Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ ÐºÐ¾Ð´, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð¼Ñ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð¸ Ð²Ð°Ð¼ Ð½Ð° ÑƒÐºÐ°Ð·Ð°Ð½Ð½Ñ‹Ð¹ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°
    					</span>
					</div>

					<div className="col-12 flexed-col">
						Ð”Ð¾ Ð¸ÑÑ‚ÐµÑ‡ÐµÐ½Ð¸Ñ ÑÑ€Ð¾ÐºÐ° Ð´ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ ÐºÐ¾Ð´Ð° <span className="seconds">{this.state.SECONDS}</span> ÑÐµÐºÑƒÐ½Ð´.
					</div>

					<div className="col-12 flexed-col">
						<label>Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½</label>
						<div className="phone-placeholder">
							{this.state.PHONE}
						</div>
					</div>
					
					<div className="col-12 flexed-col">
						<label>ÐšÐ¾Ð´</label>
						<InputMask onKeyDown={this.handleConfirm} placeholder="Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ ÐºÐ¾Ð´ Ñ Ð¡ÐœÐ¡" type="tel" className="phone" mask="9999" onChange={(text) => this.recordCode(text)} />
					</div>

					<div className="col-12 flexed-col">
						{this.renderConfirmButton()}
					</div>

					<div className="col-12 flexed-col">
						<a className="dont-remember" onClick={() => this.doRegister()}>Ð’Ð²ÐµÑÑ‚Ð¸ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° Ð·Ð°Ð½Ð¾Ð²Ð¾</a>
					</div>
			</div>
		);
    }

	render() {
		let VIEW = false;
		if (this.state.VIEW == 'REGISTER' || this.state.VIEW ==  'LOGIN') {
			VIEW = this.renderRegister();
		} else if (this.state.VIEW == 'CONFIRM') {
			VIEW = this.renderConfirm();
		}

		return VIEW;
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login)
