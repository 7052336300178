import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import CardForm  from "./../../../APP/COMPONENTS/ContentParts/CardForm"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DoPay extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        	ORDER_ID: props.ORDER,
        	NUMBER: props.NUMBER,
        	SECURITY: props.SECURITY,
        	CARDS: false,
        	CARD: 0,
        	EMAIL: this.props.USER.EMAIL,
        	ADD_CARD: false
        }
    }

    getPaymentCards = async () =>
    {
    	if (this.props.USER) {
	        try {
	            let SEND_DATA = {
	                action:    'paymentCards',
	                apikey:    this.props.USER.TOKEN
	            };
	            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
	            if (data !== false) {
	                this.setState({CARDS: data.cards});
	                if (data.cards.length < 1) {
	                	this.setState({ADD_CARD: true});
	                }
	            }
	        } catch (error) {
	            console.log('BAD_CARDS_STORY');
	        }
	    }
    }

    componentDidMount()
    {
    	this.getPaymentCards();
    }

    getCardsSource(type)
    {
        switch (type) {
            case 'Visa':
                return CONFIG.API_C_DOMAIN + '/imgs/banks/visa.png';
                break;
            case 'Maestro':
                return CONFIG.API_C_DOMAIN + '/imgs/banks/maestro.png';
                break;
            case 'Mir':
                return CONFIG.API_C_DOMAIN + 'imgs/banks/mir.png';
                break;
            case 'Mastercard':
                return CONFIG.API_C_DOMAIN + 'imgs/banks/mastercard.png';
                break;
            default:
                return '/system_images/green_card.png';
                break;
        }
    }

    renderFavCheck(ID)
    {
        if (ID == this.state.CARD) {
            return (
                <div className="card_check">
                    <img src="/system_images/green_check.png" />
                </div>
            );
        }
    }

    selectFav = async (id) =>
    {
        this.setState({CARD: id});
    }

    renderCards()
    {
        if (this.state.CARDS) {
            let RENDERED = [];

            for (let i = 0; i < this.state.CARDS.length; i++) {
                let CARD = this.state.CARDS[i];
                let TITLE = CARD.FIRST + '*****' + CARD.LAST;
                let IMAGE = this.getCardsSource(CARD.TYPE);

                if (CARD.FAV == 1) {
                	if (!this.state.CARD) {
                		this.setState({CARD: CARD.ID});
            		}
                }

                RENDERED.push(
                    <a className="user_card" key={i} onClick={() => this.selectFav(CARD.ID)}>
                        <span>
                            {CARD.FIRST} â—â—â—â— {CARD.LAST}
                        </span>
                        {this.renderFavCheck(CARD.ID)}
                    </a>
                );
            }

            /*
            <a onClick={() => this.removeCard(CARD.ID)}>
                Ð£Ð´Ð°Ð»Ð¸Ñ‚ÑŒ
            </a>
            */

            return (
                <div>
                    {RENDERED}
                </div>
            );
        }
    }

    renderPayNowHead()
    {
    	return (
    		<div className="order_white_block no_margin_top">
                <div className="cart-message">
                    <div className="flex cart_first empty_cart_head">
                        <h4>ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð·Ð°ÐºÐ°Ð·Ð° {this.state.NUMBER}</h4>
                        <a className="close_the_cart" onClick={() => this.props.openOrder(false)}>
                            <img src="/system_images/close_cart.png" />
                        </a>
                    </div>
                </div>
				<div className="done_message">
					Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ ÐºÐ°Ñ€Ñ‚Ñ‹ Ð´Ð»Ñ Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹. ÐœÑ‹ ÑÐ½Ð¸Ð¼ÐµÐ¼ Ñ Ð½ÐµÑ‘ 1 Ñ€ÑƒÐ±Ð»ÑŒ Ð´Ð»Ñ Ð¿Ñ€Ð¾Ð²ÐµÑ€ÐºÐ¸ ÐºÐ°Ñ€Ñ‚Ñ‹ Ð¸ Ð²ÐµÑ€Ð½Ñ‘Ð¼ Ð² Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ðµ ÑÑƒÑ‚Ð¾Ðº. Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð° Ð·Ð°ÐºÐ°Ð· Ð±ÑƒÐ´ÐµÑ‚ ÑÐ½ÑÑ‚Ð° ÐºÐ°Ðº Ñ‚Ð¾Ð»ÑŒÐºÐ¾ Ð·Ð°ÐºÐ°Ð· Ð±ÑƒÐ´ÐµÑ‚ Ð¿Ð¾Ð»Ð½Ð¾ÑÑ‚ÑŒÑŽ ÑÐ¾Ð±Ñ€Ð°Ð½
				</div>
    		</div>
		);
    }

    recordEmail = (text) => 
    {
        this.setState({EMAIL: text.target.value});
    }

    renderEmailPlace()
    {
    	return (
	    	<div className="email_enter_now">
		    	<div className="order_block_head">
					{this.renderCheck()}
				</div>
				<div className="flex">
	            	<div className="order_form_input_holder email_holder">
	                	<input type="email" value={this.state.EMAIL}  onChange={(text) => this.recordEmail(text)} />
	            	</div>
	        	</div>
	    	</div>
		);
    }

    changed()
    {

    }

    renderCheck()
    {
    	return (
    		<div className="p_check agree">
    			<input name="agree" type="checkbox" id="agree" value="1" checked="checked" onChange={() => this.changed()} />
    			<label htmlFor="agree">
    				<span>ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ ÐºÐ²Ð¸Ñ‚Ð°Ð½Ñ†Ð¸ÑŽ Ð½Ð° e-mail</span>
				</label>
			</div>
    	);
    }

    sendDataToServer = async () =>
    {
        this.setState({SAVING: true});

        let TOKEN = 'UNREGISTERED';
		if (this.props.USER) {
			TOKEN = this.props.USER.TOKEN;
		}

        try {
	        let SEND_DATA = {
	            action:         'addCardToOrder',
	            order:          this.state.ORDER_ID,
	            email: 			this.state.EMAIL,
	            card:           this.state.CARD,
	            security: 		this.state.SECURITY,
	            apikey:         TOKEN
	        };

	        console.log(SEND_DATA);

	        let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
	        if (data !== false) {
	            this.setState({SAVING: false});
	            this.props.payDone();
	        } else {
	            this.setState({SAVING: false});
	        }
        } catch (error) {
        	console.log(error);
            this.setState({SAVING: false});
        }
    }

    renderButton()
    {
    	if (this.state.SAVING) {
    		return (
	    		<a className="orange-button inactive-btn">
	                ÐŸÑ€Ð¸Ð²ÑÐ·Ñ‹Ð²Ð°ÐµÐ¼ ÐºÐ°Ñ€Ñ‚Ñƒ ...
	            </a>
            );
	    } else {
	    	return (
    			<a className="orange-button hoverable" onClick={() => this.sendDataToServer()}>
                   ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ
                </a>
    		)
	    }
    }

    renderMainContent()
    {
    	return (
        	<div className="order_form_holder">
	            <div className="modal_content">
	                {this.renderPayNowHead()}

	                <div className="cards_place do_pay">
	                    {this.renderCards()}

	                    <div className="add_card_holder">
	                        <a onClick={() => this.setState({ADD_CARD: true})}>
	                            <span>+</span>Ð”Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ Ð½Ð¾Ð²ÑƒÑŽ ÐºÐ°Ñ€Ñ‚Ñƒ
	                        </a>
	                    </div>
	                </div>

	                <div className="order_finals_block do_pay">
	                	<div className="pay_spacer"></div>
	                	{this.renderEmailPlace()}
	                </div>

	            </div>
	            <div className="modal_bottom">
	            	{this.renderButton()}
	            </div>
            </div>
    	)
    }

    cardAdded = (ID) =>
    {
    	this.setState({ADD_CARD: false});

    	if (!this.props.USER) {
    		this.setState({CARD: ID});
    		this.sendDataToServer();
    	} else {
    		this.getPaymentCards();
    	}
    }

    renderCardContent()
    {
    	return (
        	<div className="order_form_holder">
	            <div className="modal_content">
	                {this.renderPayNowHead()}

	                <CardForm cardAdded={this.cardAdded} SECURITY={this.state.SECURITY} />

	            </div>
            </div>
    	)
    }

	render()
    {
    	if (this.state.ADD_CARD) {
            return this.renderCardContent();
    	} else {
    		return this.renderMainContent();
    	}
 
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DoPay);