import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import MapHelper from "./../../../APP/HELPERS/MapHelper"

import DoPay from "./../../../APP/COMPONENTS/ContentParts/DoPay"
import Done from "./../../../APP/COMPONENTS/ContentParts/Done"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        TOKEN: state.TOKEN,
        FRIENDS_DISCOUNT: state.FRIENDS_DISCOUNT,
        PRICE_TYPE: state.PRICE_TYPE
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class OrderForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);

		let PHONE = '';
        let NAME = '';
        let ENTRANCE = '';
        let APPARTMENT = '';
        let FLOOR = '';
        let ADRESS = '';
        let EMAIL = '';
        let TEMP_ADRESS = '';

        if (this.props.USER) {
        	PHONE = this.props.USER.PHONE;
            NAME = this.props.USER.NAME;
            ENTRANCE = props.USER.ENTRANCE;
            APPARTMENT = props.USER.APPARTMENT;
            FLOOR = props.USER.FLOOR;
            ADRESS = props.USER.ADRESS;
            EMAIL = props.USER.EMAIL;
            TEMP_ADRESS = props.USER.ADRESS;
        }

        this.state = {
            OK: false,
            SUGGESTIONS: false,
            PAYMENTS_DROP: false,
            BONUSES_DROP: false,
            COUPON_DROP: false,
            NEW_COUPON: false,
            ORDERING: false,

            NEW_BONUSES: 	0,
            BONUSES_AMOUNT: 0,
            COUPON_DISCOUNT:false,
            COUPON_NAME:    '',
            COUPON_TYPE:    false,
            COUPON_ID:      false,
            COUPON_AMOUNT:  false,
            NEW_COUPON: 	'',

            PHONE: PHONE,
            NAME: NAME,
            ENTRANCE: ENTRANCE,
            APPARTMENT: APPARTMENT,
            FLOOR: FLOOR,
            MKAD: '',
            GPS: [],
            COMMENT: '',
            ADRESS: ADRESS,
            EMAIL: EMAIL,
            TEMP_ADRESS: ADRESS,
            BONUSES: 0,
            TIMES: [],
            DATES: [],
            DELIVERY_PRICE: 0,
            DEL_ADDON: 0,
            FREE_DELIVERY: 9999999999999999,
            PAYMENT: 1,
            TOTAL_SUM: 0,
            TIME: false,
            DATE: false,
            DELIVERY: 2,

            DOMOPHONE: '',
            CALL_DOMOPHONE: false,

            NAME_ERROR: false,
            PHONE_ERROR: false,
            ADRESS_ERROR: false,
            COUPON_ERROR: false,
            ADRESS_CONFIRMED: false,
            ROUTE: 'FORM',

            ORDER: 0,
            NUMBER: ''
        };

        this.setMapHelper = this.setMapHelper.bind(this);
        this.setSugRef = this.setSugRef.bind(this);
        this.setPayRef = this.setPayRef.bind(this);
        this.setBonRef = this.setBonRef.bind(this);
        this.setCouRef = this.setCouRef.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount = async () =>
    {
    	document.addEventListener('mousedown', this.handleClickOutside);

    	this.validatePhone(this.state.PHONE);
    	this.validateName(this.state.NAME);

    	let sum = await this.countNewCart();
        let datetime = await this.getAllowedOrderTimes();

        this.setState({
            TIMES: datetime.TIMES,
            DATES: datetime.DATES,
            DELIVERY_PRICE: datetime.DELIVERY_PRICE,
            FREE_DELIVERY: datetime.FREE_DELIVERY,
            PAYMENT:    1,
            SUM: sum,
            TOTAL_SUM:   sum
        });
    }

    mapLoaded = (data) =>
    {
    	if (this.state.ADRESS.length > 0) {
    		this.selectAdress(this.state.ADRESS);
		}
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    recordCommerceData(ORDER_NUMBER, PRODUCTS)
    {
        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('MADE_A_PURCHASE');
        }

        window.ym(88250055,'reachGoal','orderSubmit')

        if (typeof window.dataLayer !== "undefined") {
            let COMMERCE_PRODUCTS = [];
            let KEYS = Object.keys(PRODUCTS);

            if (KEYS.length) {
                for (let ID of KEYS) {
                    if (typeof this.props.PRODUCTS[ID] !== "undefined") {
                        let PRODUCT = this.props.PRODUCTS[ID];
                        if (PRODUCT.availableones >= PRODUCTS[ID]) {       
                            COMMERCE_PRODUCTS.push({
                                "id": PRODUCT.id,
                                "name": PRODUCT.title,
                                "price": PRODUCT.price,
                                "quantity": PRODUCTS[ID]
                            });

                        }
                    }
                }
            }

            window.dataLayer.push({
                "ecommerce": {
                    "purchase": {
                        "actionField": {
                            "id" : ORDER_NUMBER
                        },
                        "products": COMMERCE_PRODUCTS
                    }
                }
            });
        }
    }

    submitOrder = async () =>
    {
		this.setState({ORDERING: true});

		let TOKEN = 'UNREGISTERED';
		if (this.props.USER) {
			TOKEN = this.props.USER.TOKEN;
		}

    	try {
            let SEND_DATA = {
                action:     'submitOrderFromApp',
                apikey:     TOKEN,
                gps:        this.state.GPS,
                products:   this.props.CART,
                name:       this.state.NAME,
                email:      this.state.EMAIL,
                adress:     this.state.ADRESS,
                phone:      this.state.PHONE,
                coupon:     this.state.COUPON_NAME,
                bonuses:    this.state.BONUSES_AMOUNT,
                date:       this.state.DATE,
                time:       this.state.TIME,
                delivery:   this.state.DELIVERY,
                payment:    this.state.PAYMENT,
                point:      this.state.POINT,
                comment:    this.state.COMMENT,
                appartment: this.state.APPARTMENT,
                floor:      this.state.FLOOR,
                entrance:   this.state.ENTRANCE,
                mkad:       this.state.MKAD,
                delivery_addon: this.state.DEL_ADDON,
                domophone: this.state.DOMOPHONE,
                call_domophone: this.state.CALL_DOMOPHONE,
                point: 		0,
                source:     0,
                price_type: this.props.PRICE_TYPE
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                this.setState({ORDERING: false});

                this.recordCommerceData(data.number,this.props.CART);
                
                if ((this.state.PAYMENT == 1) || (this.state.PAYMENT == 4)) {
                	this.setState({ROUTE: 'DONE', ORDER: data.order, NUMBER: data.number, SECURITY: data.security});
                	this.props.changeHead('Ð—Ð°ÐºÐ°Ð· Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½',false);
                } else {
                	this.setState({ROUTE: 'DO_PAY', ORDER: data.order, NUMBER: data.number, SECURITY: data.security});
                	this.props.changeHead('ÐžÐ¿Ð»Ð°Ñ‚Ð°',false);
                }
                this.props.emptyCart();
            } else {
                this.setState({ORDERING: false});
            }
        } catch (error) {
            this.setState({ORDERING: false});
        }
    }

    countTheDiscount = () =>
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM_THIS = 0;
        let SUM_NEW = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let PRICE = PRODUCTS[ID].price;
                        let ROW_SUM2 = PRICE * CART[ID];
                        SUM_THIS += ROW_SUM2;
                        if (this.props.FRIENDS_DISCOUNT) {
                            PRICE = PRICE - (Math.round(PRICE / 100 * 25));
                        }
                        let ROW_SUM = PRICE * CART[ID];
                        SUM_NEW += ROW_SUM;
                    }
                }
            }
        }

        return SUM_THIS - SUM_NEW;
    }

    countNewCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let PRICE = PRODUCTS[ID].price;
                        if (this.props.FRIENDS_DISCOUNT) {
                            PRICE = PRICE - (Math.round(PRICE / 100 * 25));
                        }
                        if (this.props.USER && this.props.USER.price_type > 0 || this.props.PRICE_TYPE) {
                            PRICE = PRODUCTS[ID]['price' + (this.props.PRICE_TYPE ? this.props.PRICE_TYPE : this.props.USER.price_type)];
                        }
                        
                        let ROW_SUM = PRICE * CART[ID];
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        this.SUM = SUM;

        return SUM;
    }

    countCart()
    {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let PRICE = PRODUCTS[ID].price;
                        let ROW_SUM = PRICE * CART[ID];
                        SUM += ROW_SUM;
                    }
                }
            }
        }

		this.SUM = SUM;

        return SUM;
    }

    getAllowedOrderTimes = async () =>
    {
        try {
            let SEND_DATA = {
                action:     'getAllowedOrderTimes',
                apikey:     this.props.TOKEN,
                products:   this.props.CART,
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                return data;
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

    getBonuses = async () =>
    {
    	if (this.props.USER) {
	        try {
	            let SEND_DATA = {
	                action:    'getBonusesAndShares',
	                apikey:    this.props.USER.TOKEN
	            };
	            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

	            if (data !== false) {
	                this.props.setBonusesAmount(data.bonuses);
	                return data;
	            }
	        } catch (error) {
	            console.log('BAD PRODUCTS TIMER');
	        }
	    } else {
	    	return {bonuses: 0}
	    }
    }

    handleClickOutside(event) {
        if (this.sug && !this.sug.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({SUGGESTIONS: false});
            }
        }
        if (this.pay && !this.pay.contains(event.target)) {
            if (this.state.PAYMENTS_DROP) {
                this.setState({PAYMENTS_DROP: false});
            }
        }
        if (this.bon && !this.bon.contains(event.target)) {
            if (this.state.BONUSES_DROP) {
                this.setState({BONUSES_DROP: false});
            }
        }
        if (this.cou && !this.cou.contains(event.target)) {
            if (this.state.COUPON_DROP) {
                this.setState({COUPON_DROP: false});
            }
        }
    }

    setSugRef(node) {
        this.sug = node;
    }

    setPayRef(node) {
        this.pay = node;
    }

    setBonRef(node) {
        this.bon = node;
    }

    setCouRef(node) {
        this.cou = node;
    }

    setMapHelper = (node) =>
    {
        this.mapHelper = node;
    }

    renderSuggestions(suggestions)
    {
        let RESULTS = [];

        for (let i = 0; i < suggestions.length; i++) {
            RESULTS.push(
                <a 
                    key={i}
                    onClick={() => this.selectAdress(suggestions[i].TITLE)}
                >
                    {suggestions[i].TITLE}
                </a>
            )
        }

        let SUGGESTIONS = (
            <div className="suggestions_block" ref={this.setSugRef}>
                {RESULTS}
            </div>
        );

        this.setState({SUGGESTIONS: SUGGESTIONS, ADRESS_ERROR: false, ADRESS_CONFIRMED: false});
    }

    setSuggestions = (suggestions) =>
    {
        if (suggestions.length) {
            this.renderSuggestions(suggestions);
        } else {
            this.setState({SUGGESTIONS: false, ADRESS_ERROR: true, ADRESS_CONFIRMED: false});
        }
    }

    setCheckedAdress = (result, adress, gps, mkad) =>
    {
        if (result) {
            this.setState({TEMP_ADRESS: adress, ADRESS: adress, ADRESS_ERROR: false, ADRESS_CONFIRMED: true, GPS: gps, MKAD: mkad});
            this.countAppendDelivery()
        } else {
            this.adressInput.focus();
            this.setState({TEMP_ADRESS: adress, ADRESS: adress, ADRESS_CONFIRMED: false, GPS: [], MKAD: 0});
            this.mapHelper.getSuggestions(adress);
            this.countAppendDelivery()
        }
    }

    countAppendDelivery()
    {
        if (!this.props.FRIENDS_DISCOUNT) {
        	if (this.state.MKAD > 0) {
        		let km = this.state.MKAD;
        		let sum_default = this.SUM;
                let default_delivery_price = this.state.DELIVERY_SUM;

                let SET_SUM = 0;
            
                if (sum_default < 8500) {
                    if (km < 30) {
                        SET_SUM = km * 30;
                    } else {
                        SET_SUM = km * 50;
                    }
                } else {
                    if (km > 30) {
                        var use_km = km - 30;
                        SET_SUM = use_km * 50;
                    }
                }
            
                let mkad = km;
            	let delivery_add = 0;

                if (SET_SUM > 0) {
                    delivery_add = SET_SUM;
                }

                if (this.props.FRIENDS_DISCOUNT) {
                    delivery_add = 0;
                }
                
                this.setState({DEL_ADDON: delivery_add});
        	}
        }
    }

    selectAdress = (adress) =>
    {
        this.setState({SUGGESTIONS: false, TEMP_ADRESS: adress, ADRESS: adress, ADRESS_ERROR: true});
        this.mapHelper.checkAdress(adress);
    }

    recordPhone = (text) => 
    {
        this.setState({PHONE: text.target.value});
        this.validatePhone(text.target.value);
    }

    recordName = (text) => 
    {
        this.setState({NAME: text.target.value});
        this.validateName(text.target.value);
    }

    recordAdress = (text) => 
    {

        this.setState({TEMP_ADRESS: text.target.value, ADRESS_CONFIRMED: false, ADRESS: text.target.value, GPS: [], MKAD: 0});
        this.mapHelper.getSuggestions(text.target.value);
    }

    recordEntrance = (text) => 
    {
        this.setState({ENTRANCE: text.target.value});
    }

    recordAppartment = (text) => 
    {
        this.setState({APPARTMENT: text.target.value});
    }

    recordDomophone = (text) => 
    {
        this.setState({DOMOPHONE: text.target.value});
    }

    recordFloor = (text) => 
    {
        this.setState({FLOOR: text.target.value});
    }

    recordComment = (text) => 
    {
        this.setState({COMMENT: text.target.value});
    }

    validateName = (name) =>
    {
    	if (name.length > 1) {
    		if (this.state.NAME_ERROR) {
    			this.setState({NAME_ERROR: false});
    		}
    	} else {
    		if (!this.state.NAME_ERROR) {
    			this.setState({NAME_ERROR: true});
    		}
    	}
    }

    validatePhone = (phone) =>
    {
        let phoneNumber = phone.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
            if (this.state.PHONE_ERROR) {
    			this.setState({PHONE_ERROR: false});
    		}
        } else {
            if (!this.state.PHONE_ERROR) {
    			this.setState({PHONE_ERROR: true});
    		}
        }
    }

    renderForm()
    {
    	let CLASS = 'order-input adress';
        if (this.state.ADRESS_ERROR) {
            CLASS = 'order-input adress errored';
        }

    	return (
    		<div className="order_first_block">

                <div className="order_form_header">
                    Ð’Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€
                </div>
                <div className="flex">
                    <InputMask placeholder="Ð’Ð°Ñˆ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½" type="tel" className="order-input phone" mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)} value={this.state.PHONE} />
                </div>

                <div className="order_form_header">
                    ÐŸÐ¾Ð»ÑƒÑ‡Ð°Ñ‚ÐµÐ»ÑŒ
                </div>
                <div className="flex">
                    <input type="text" className="order-input name" placeholder="Ð’Ð°ÑˆÐµ Ð¸Ð¼Ñ" value={this.state.NAME}  onChange={(text) => this.recordName(text)} />
                </div>

                <div className="order_form_header">
                    ÐÐ´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸
                </div>
                <div className="flex">
                	<div className="order_form_input_holder adress_holder">
                		<label>ÐÐ´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸</label>
                		<textarea className={CLASS} name="adress" onChange={(text) => this.recordAdress(text)} value={this.state.TEMP_ADRESS} ref={(input) => { this.adressInput = input; }}></textarea>
                		{this.state.SUGGESTIONS}
            		</div>
        		</div>

        		<div className="flex">
                	<div className="order_form_input_holder appartment_holder">
                		<label>ÐšÐ²/Ð¾Ñ„</label>
                		<input type="text" className="order-input" name="appartment" value={this.state.APPARTMENT}  onChange={(text) => this.recordAppartment(text)} />
            		</div>
            		<div className="order_form_input_holder floor_holder">
                		<label>Ð­Ñ‚Ð°Ð¶</label>
                		<input type="text" className="order-input" name="floor" value={this.state.FLOOR}  onChange={(text) => this.recordFloor(text)} />
            		</div>
            		<div className="order_form_input_holder entrance_holder">
                		<label>ÐŸÐ¾Ð´ÑŠÐµÐ·Ð´</label>
                		<input type="text" className="order-input" name="entrance" value={this.state.ENTRANCE}  onChange={(text) => this.recordEntrance(text)} />
            		</div>
        		</div>

                <div className="flex">
                    <div className="order_form_input_holder appartment_holder">
                        <label>Ð”Ð¾Ð¼Ð¾Ñ„Ð¾Ð½</label>
                        <input type="text" className="order-input" value={this.state.DOMOPHONE}  onChange={(text) => this.recordDomophone(text)} />
                    </div>
                        {this.renderCallDomophone()}
                </div>

        		<div className="flex">
                	<div className="order_form_input_holder adress_holder da_comment">
                        <label>ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ</label>
                		<textarea className="order-input adress" onChange={(text) => this.recordComment(text)} value={this.state.COMMENT}></textarea>
            		</div>
        		</div>
            </div>
		);
    }

    toggleDomophone = () =>
    {
        let CHECK = true;
        if (this.state.CALL_DOMOPHONE) {
            CHECK = false;
        }

        this.setState({CALL_DOMOPHONE: CHECK});
    }

    renderCallDomophone = () =>
    {
        let CLASS = 'da_check';
        if (this.state.CALL_DOMOPHONE) {
            CLASS = 'da_check checked';
        }

        return (
            <div className="domophone_check" onClick={() => this.toggleDomophone()}>
                <div className={CLASS}></div>
                <div>ÐÐµ Ð·Ð²Ð¾Ð½Ð¸Ñ‚ÑŒ Ð² Ð´Ð¾Ð¼Ð¾Ñ„Ð¾Ð½</div>
            </div>
        );
    }

    timeChanged(event)
    {
		this.setState({TIME: event.target.value});
	}

    dateChanged(event)
    {
    	this.setState({DATE: event.target.value, TIME: false});
	}

	getTheTimes()
	{
    	let DATES = [];
        let BUSY = [];
        let LIMIT = [];
        let DATE_KEY = 0;

        for (let KEY in this.state.DATES) {
            if (this.state.DATE) {
                if (this.state.DATES[KEY].VALUE == this.state.DATE) {
                    BUSY = this.state.DATES[KEY].BUSY;
                    LIMIT = this.state.DATES[KEY].LIMIT;
                    DATE_KEY = KEY;
                }
            } else {
                if (KEY == 0) {
                	if (this.state.DATE !== this.state.DATES[KEY].VALUE) {
                    	this.setState({DATE: this.state.DATES[KEY].VALUE});
                	}
                    BUSY = this.state.DATES[KEY].BUSY;
                    LIMIT = this.state.DATES[KEY].LIMIT;
                }
            }
            DATES.push(
            	<option 
	            	key={'date_' + KEY}
	                value={this.state.DATES[KEY].VALUE}
                >
                	{this.state.DATES[KEY].TITLE}
                </option>
            );
        }

        let TIMES = [];
        let j = 0;

        for (let KEY in this.state.TIMES) {

            let CONTINUE = true;
            let VALUE = this.state.TIMES[KEY].VALUE;
            let DISABLED = parseInt(this.state.TIMES[KEY].DATA_DISABLED);

            
            if (parseInt(DATE_KEY) === 0) {
                if (DISABLED == 1) {
                    CONTINUE = false;
                }
            }

            if (CONTINUE) {
                if (LIMIT[VALUE] - BUSY[VALUE] > 0) {
                    if (!this.state.TIME) {
                        if (j == 0) {
                        	if (this.state.TIME !== this.state.TIMES[KEY].VALUE) {
                            	this.setState({TIME: this.state.TIMES[KEY].VALUE});
                        	}
                        }
                    }
                    TIMES.push(
                    	<option 
			            	key={'time_' + KEY}
			                value={this.state.TIMES[KEY].VALUE}
		                >
		                	{this.state.TIMES[KEY].TITLE}
		                </option>

                    );
                    j++;
                }
            }
        }

        return {
        	DATES: DATES,
        	TIMES: TIMES
        }
	}

	renderPayment(id)
	{
		switch(id) {
			case 1:
				return (<div className="the_payment">ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð½Ð°Ð»Ð¸Ñ‡Ð½Ñ‹Ð¼Ð¸</div>);
			case 4:
				return (<div className="the_payment">ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ð¾Ð¹</div>);
		}
	}

	selectPayment(id)
	{
		this.setState({PAYMENT: id, PAYMENTS_DROP: false})
	}

	changePayment()
	{
		let PAYMENTS_DROP = (
			<div className="suggestions_block" ref={this.setPayRef}>
				<a onClick={() => this.selectPayment(1)}>
					{this.renderPayment(1)}
				</a>
				<a onClick={() => this.selectPayment(4)}>
					{this.renderPayment(4)}
				</a>
			</div>
		);

		this.setState({PAYMENTS_DROP: PAYMENTS_DROP});
	}

	renderPayments()
	{
		return (
			<div className="order_form_input_holder payment_holder">
				<a className="payment_selected" onClick={() => this.changePayment()}>
					{this.renderPayment(this.state.PAYMENT)}
				</a>
				{this.state.PAYMENTS_DROP}
			</div>
		);
	}

    renderTimeAndPayment()
    {
    	let DATA = this.getTheTimes();

    	return (
    		<div className="order_first_block">
    			<div className="order_form_header">
                    Ð’Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸
                </div>
    			<div className="flex">
                	<div className="order_form_input_holder date_holder">
                		<select className="order-input date" onChange={(event) => this.dateChanged(event)}>
                			{DATA.DATES}
                		</select>
            		</div>
            		<div className="order_form_input_holder time_holder">
                		<select className="order-input time" onChange={(event) => this.timeChanged(event)}>
                			{DATA.TIMES}
                		</select>
            		</div>
        		</div>

                <div className="order_form_header">
                    Ð¡Ð¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹
                </div>

                <div className="flex">
                    {this.renderPayments()}
                </div>


                {this.renderPromocodeForm()}
                
    		</div>
		);




    }


    renderPromocodeForm = () =>
    {
        return false;
        
        let CLASS = 'order-input bonuses';
        if (this.state.COUPON_ERROR) {
            CLASS = 'order-input bonuses error_input';
        }

        return (
            <div>
                <div className="order_form_header">
                    ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´
                </div>

                <div className="promocode_holder">
                    <div className="order_form_input_holder">
                        <label>ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´</label>
                        <input type="text" className={CLASS} onChange={(text) => this.changeCoupon(text)} value={this.state.NEW_COUPON} />
                    </div>
                    <a className="orange-button" onClick={() => this.useCoupon()}>ÐŸÑ€Ð¸Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ</a>
                </div>
            </div>
        );
    }

    useCoupon = async() =>
    {
        if (this.state.NEW_COUPON !== '') {
            try {
                let SEND_DATA = {
                    action:    'checkCoupon',
                    coupon:     this.state.NEW_COUPON,
                    apikey:     this.props.TOKEN
                };
                let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                if (data !== false) {
                    this.yesCoupon(data.promocode);
                } else {
                    this.noCoupon();
                }
            } catch (error) {
            	this.noCoupon();
            }
        } else {
            this.noCoupon();
        }
    }

    countPercentDiscount()
    {

        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);

        let DISCOUNTED_SUM = 0;
        let DISCOUNT_SUM = 0;
        
        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let PURE_ROW_SUM = PRODUCTS[ID].price * CART[ID];

                        let DISCOUNT = PRODUCTS[ID].price / 100 * this.state.COUPON_DISCOUNT;
                        let PRICE = PRODUCTS[ID].price - DISCOUNT;
                        let ROW_SUM = PRICE * CART[ID];
                        DISCOUNT_SUM += PURE_ROW_SUM - ROW_SUM;
                        DISCOUNTED_SUM += ROW_SUM;
                    }
                }
            }
        }

        DISCOUNT_SUM = Math.floor(DISCOUNT_SUM);

        this.setState({
            COUPON_AMOUNT: DISCOUNT_SUM,
            DISCOUNTED_SUM: DISCOUNTED_SUM
        });
    }

    countDiscounts()
    {
        if (this.state.COUPON_NAME !== "") {
            if (this.state.COUPON_TYPE == 0) {
                let SUM = this.SUM - this.state.COUPON_DISCOUNT;
                this.setState({
                    COUPON_AMOUNT: this.state.COUPON_DISCOUNT,
                    DISCOUNTED_SUM: SUM
                });
            } else if (this.state.COUPON_TYPE == 1) {
                this.countPercentDiscount();
            } else {
            	this.setState({
                    COUPON_AMOUNT: 0,
                    DISCOUNTED_SUM: 0
                });
            }
        }

        this.countNewCart();
        this.countAppendDelivery();
    }

    yesCoupon = (promocode) =>
    {
    	this.setState({
            COUPON_DISCOUNT: promocode.discount,
            COUPON_NAME: promocode.name,
            COUPON_TYPE: promocode.type,
            COUPON_ID: promocode.id,
            COUPON_DROP: false,
            COUPON_ERROR: false
        });

        this.countDiscounts();
    }

    noCoupon = () =>
    {	
    	this.setState({
            COUPON_DISCOUNT:false,
            COUPON_NAME:    '',
            COUPON_TYPE:    false,
            COUPON_ID:      false,
            COUPON_AMOUNT:  false,
            DISCOUNTED_SUM: 0,
            COUPON_ERROR: 	true
        });
    }

    changeCoupon = (code) => 
    {
        this.setState({NEW_COUPON: code.target.value});
    }

    enterCoupon()
    {
    	if (this.state.COUPON_DROP) {
	    	
	    }
    }

    useBonuses = () => 
    {
    	let MAX_BONUSES = this.countMaxBonuses();
    	let BONUSES = this.state.NEW_BONUSES;

        if (BONUSES > MAX_BONUSES) {
            BONUSES = MAX_BONUSES;
        }

        this.countNewCart();

    	this.setState({BONUSES_AMOUNT: BONUSES, NEW_BONUSES: BONUSES, BONUSES_DROP: false});

        this.countAppendDelivery();
    }

    countMaxBonuses()
    {
        let MAX = Math.ceil(this.SUM / 100 * 30);

        return MAX;
    }

    changeBonuses = (code) => 
    {
    	let SET_BONUSES = code.target.value;
    	if (SET_BONUSES > this.state.BONUSES) {
    		SET_BONUSES = this.state.BONUSES;
    	} else if (SET_BONUSES < 0) {
    		SET_BONUSES = 0;
    	}
        this.setState({NEW_BONUSES: code.target.value});
    }

    enterBonuses()
    {
    	if (this.state.BONUSES_DROP) {
	    	return (
	    		<div className="form-pop-append" id="code-pop" ref={this.setCouRef}>
	                <div className="form-fields">
	                    <div className="col-8 npr">
	                        <input type="number" className="bonuses" onChange={(text) => this.changeBonuses(text)} value={this.state.NEW_BONUSES} />
	                    </div>
	                    <div className="col-4 npl">
	                        <a className="orange-button" onClick={() => this.useBonuses()}>ÐŸÑ€Ð¸Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ</a>
	                    </div>
	                </div>
	            </div>
	    	);
	    }
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderUseBonus()
    {
    	if (this.state.BONUSES > 0) {
    		let LEFT_BONUSES = this.state.BONUSES - this.state.BONUSES_AMOUNT;

    		return (
    			<div className="use_something_row">
    				<a onClick={() => this.setState({BONUSES_DROP: true})}>
    					Ð˜ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹
    				</a>
    				<div className="order_current_bonuses">
    					ÐžÑÑ‚Ð°Ñ‚Ð¾Ðº: {LEFT_BONUSES} {this.num_title(LEFT_BONUSES, ['ÑÐºÐ¾Ð±Ð°Ð»Ð»', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð°', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²'])}
    				</div>
    			</div>
    		);
    	}
    }

    renderAllDiscounts()
    {
    	return (
    		<div className="order_white_block">
    			<div className="use_something_row">
    				<a onClick={() => this.setState({COUPON_DROP: true})}>
    					Ð£ Ð¼ÐµÐ½Ñ ÐµÑÑ‚ÑŒ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´
    				</a>
    			</div>
    			{this.renderUseBonus()}
    			{this.enterBonuses()}
    			{this.enterCoupon()}
    		</div>
    	);
    }

    renderBonusesUsed()
    {
    	let LEFT_BONUSES = this.state.BONUSES - this.state.BONUSES_AMOUNT;

    	return (
    		<div className="order_white_block">
    			<div className="use_something_row">
    				<a onClick={() => this.setState({BONUSES_DROP: true})} className="appended">
    					Ð’Ñ‹ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð»Ð¸ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹ <img src="/system_images/green_check.png" />
    				</a>
    				<div className="order_current_bonuses">
    					ÐžÑÑ‚Ð°Ñ‚Ð¾Ðº: {LEFT_BONUSES} {this.num_title(LEFT_BONUSES, ['ÑÐºÐ¾Ð±Ð°Ð»Ð»', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð°', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²'])}
    				</div>
    			</div>

    			{this.enterBonuses()}
    			{this.enterCoupon()}
    		</div>
    	);
    }

    renderCouponUsed()
    {
    	return (
    		<div className="order_white_block">
    			<div className="use_something_row">
    				<a onClick={() => this.setState({COUPON_DROP: true})} className="appended">
    					Ð’Ñ‹ Ð¿Ñ€Ð¸Ð¼ÐµÐ½Ð¸Ð»Ð¸ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´  <img src="/system_images/green_check.png" />
    				</a>
    			</div>

    			{this.enterBonuses()}
    			{this.enterCoupon()}
    		</div>
    	);
    }

    renderBonusesBlock()
    {
    	if ((this.state.BONUSES_AMOUNT == 0) && (this.state.COUPON_NAME == '')) {
    		return this.renderAllDiscounts();
		} else if (this.state.BONUSES_AMOUNT > 0) {
			return this.renderBonusesUsed();
		} else {
			return this.renderCouponUsed();
		}
    }

    renderProductsSum()
    {
    	return (
    		<div className="finals_row">
    			Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²: {this.SUM} Ñ€
    		</div>
    	);
    }

    renderDeliverySum()
    {
        let SUM = this.SUM;
    	let DELIVERY_SUM = this.state.DELIVERY_PRICE;

        if (SUM > this.state.FREE_DELIVERY) {
            DELIVERY_SUM = 0;
        }

        if (this.props.USER.DELIVERY > 0) {
            DELIVERY_SUM += parseInt(this.props.USER.DELIVERY);
        }

        if (this.state.COUPON_NAME !== '') {
            if (this.state.COUPON_TYPE == 2) {
                DELIVERY_SUM = 0;
            }
        }

        if (this.state.DEL_ADDON) {
            DELIVERY_SUM += parseInt(this.state.DEL_ADDON);
        }

        if (DELIVERY_SUM > 0) {
            DELIVERY_SUM = DELIVERY_SUM + ' Ñ€';
        } else {
            DELIVERY_SUM = 'Ð‘ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾!';
        }

        if (this.props.FRIENDS_DISCOUNT) {
            DELIVERY_SUM = 'Ð‘ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾!';
        }

        let DEL_TITLE = 'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸: ';
        if (this.state.MKAD) {
            DEL_TITLE = 'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸' + ' (' + this.state.MKAD + ' Ð·Ð° ÐœÐšÐÐ”): ';
        }

        if (this.props.FRIENDS_DISCOUNT) {
            DEL_TITLE = 'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸: ';
        }

        return (
    		<div className="finals_row">
    			{DEL_TITLE}{DELIVERY_SUM}
    		</div>
    	);
    }

    renderDiscountsSum()
    {
        if (this.props.FRIENDS_DISCOUNT) {
            return (
                <div className="finals_row red">
                    Ð’Ð°ÑˆÐ° ÑÐºÐ¸Ð´ÐºÐ°: - {this.countTheDiscount()} Ñ€
                </div>
            );
        }
    	if (this.state.BONUSES_AMOUNT > 0) {
    		return (
	    		<div className="finals_row red">
	    			Ð­ÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹: - {this.state.BONUSES_AMOUNT} Ñ€
	    		</div>
	    	);
		} else if (this.state.COUPON_AMOUNT > 0) {
			return (
	    		<div className="finals_row red">
	    			ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´: - {this.state.COUPON_AMOUNT} Ñ€
	    		</div>
	    	);
		}
    }



    countTotalsSum()
    {
		let SUM = this.countNewCart();

        let DELIVERY_SUM = this.state.DELIVERY_PRICE;
        if (SUM > this.state.FREE_DELIVERY) {
            DELIVERY_SUM = 0;
        }

        if (this.props.USER.DELIVERY > 0) {
            DELIVERY_SUM += parseInt(this.props.USER.DELIVERY);
        }

        if (this.state.BONUSES_AMOUNT > 0) {
            SUM -= parseInt(this.state.BONUSES_AMOUNT);
        }

        if (this.state.COUPON_AMOUNT > 0) {
            SUM -= parseInt(this.state.COUPON_AMOUNT);
        }

        if (this.state.COUPON_NAME !== '') {
            if (this.state.COUPON_TYPE == 2) {
                DELIVERY_SUM = 0;
            }
        }

        if (DELIVERY_SUM > 0) {
            DELIVERY_SUM = DELIVERY_SUM;
        } else {
            DELIVERY_SUM = 0;
        }

        if (this.state.DEL_ADDON) {
            DELIVERY_SUM += parseInt(this.state.DEL_ADDON);
        }

        if (this.props.FRIENDS_DISCOUNT) {
            DELIVERY_SUM = 0;
        }

        this.TOTAL_SUM = SUM;

        SUM += DELIVERY_SUM;

        return SUM;
    }

    renderTotals()
    {
    	return (
    		<div className="finals_row total">
    			Ð˜Ñ‚Ð¾Ð³: {this.countTotalsSum()} Ñ€
    		</div>
    	)
    }

    change()
    {

    }

    renderCheck()
    {
    	return (
    		<div className="p_check agree">
    			<input name="agree" type="checkbox" id="agree" value="1" checked="checked" onChange={() => this.change()} />
    			<label htmlFor="agree">
    				<span>ÐŸÑ€Ð¸Ð½Ð¸Ð¼Ð°ÑŽ ÑƒÑÐ»Ð¾Ð²Ð¸Ñ <a target="_blank" href="/company/polzovatelskoe-soglashenie/">Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ð°-Ð¾Ñ„ÐµÑ€Ñ‚Ñ‹</a></span>
				</label>
			</div>
    	);
    }

    renderFinalsBlock()
    {
		return (
			<div className="order_finals_block">
				{this.renderProductsSum()}
				{this.renderDeliverySum()}
				{this.renderDiscountsSum()}
				{this.renderTotals()}
			</div>
		);
    }

    renderSubmit()
    {
    	if (!this.state.ORDERING) {
	    	if (
	    		(this.state.DATE)
	    		&&
	    		(this.state.TIME)
	    		&&
	    		(!this.state.PHONE_ERROR)
	    		&&
	    		(this.state.ADRESS.length > 0)
	    		&&
	    		(!this.state.NAME_ERROR)
	    		) {
	    		return (
	    			<a className="orange-button hoverable" onClick={() => this.submitOrder()}>
	                    ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸
	                </a>
	    		)
	    	} else {
	    		return (
		    		<a className="orange-button inactive-btn">
		                ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸
		            </a>
	            );
	    	}
	    } else {
	    	return (
    			<a className="orange-button hoverable">
                   Ð—Ð°ÐºÐ°Ð· Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÑÐµÑ‚ÑÑ ...
                </a>
    		)
	    }
    }

    payDone = () =>
    {
    	this.props.changeHead('Ð—Ð°ÐºÐ°Ð· Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½',false);
    	this.setState({ROUTE: 'DONE'});
    }

	render()
    {
    	if (this.state.ROUTE == 'FORM') {
	        return (
	        	<div className="order_form_holder">
		            <div className="modal_content">
                        <div className="cart-message">
                            <div className="flex cart_first empty_cart_head">
                                <h4>ÐžÑ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ðµ Ð·Ð°ÐºÐ°Ð·Ð°</h4>
                                <a className="close_the_cart" onClick={() => this.props.openOrder(false)}>
                                    <img src="/system_images/close_cart.png" />
                                </a>
                            </div>
                        </div>


		                {this.renderForm()}
		                {this.renderTimeAndPayment()}

		                {this.renderFinalsBlock()}
		            </div>
		            <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
		            	{this.renderSubmit()}
		            </div>

		            <MapHelper 
                        CENTER={[55.70849105231936, 37.59300249013515]}  
                        ref={this.setMapHelper} 
                        GPS={true} 
                        setSuggestions={this.setSuggestions} 
                        mapLoaded={this.mapLoaded} 
                        setCheckedAdress={this.setCheckedAdress} 
                    />
	            </div>
	        );
	    } else if (this.state.ROUTE == 'DO_PAY') {
	    	return <DoPay ORDER={this.state.ORDER} NUMBER={this.state.NUMBER} payDone={this.payDone} SECURITY={this.state.SECURITY} />
	    } else {
	    	return <Done ORDER={this.state.ORDER} NUMBER={this.state.NUMBER}  SECURITY={this.state.SECURITY} />
	    }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderForm);