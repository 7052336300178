import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN,
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CommentForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            STARS: false,
            ID: props.ID,
            COMMENT: '',
            NAME: props.NAME
        };
    }

    leaveComment = async () =>
    {
        if (this.state.COMMENT.length > 5) {
            let SEND_DATA = {
                action:         'leaveComment',
                apikey:         this.props.USER.TOKEN,
                name:           this.state.NAME,
                comment:        this.state.COMMENT,
                id:             this.state.ID,
                stars:          this.state.STARS
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({COMMENT: '',NAME: '',STARS: 0});
            }
        }
    }

    updateStars = (e) => {
        if(e.target.checked) {
           this.setState({STARS: e.target.value});
        }
    }

    recordComment = (text) => 
    {
        this.setState({COMMENT: text.target.value});
    }

    recordName = (text) => 
    {
        this.setState({NAME: text.target.value});
    }

    renderForm()
    {
        return (
            <div id="comment" className="row flex flex-wrap form-fields">
                <div className="col-12 flexed-col">
                    <label>Ð˜Ð¼Ñ</label>
                    <input className="ui_input requiredinput" type="text" name="name" value={this.state.NAME} onChange={(text) => this.recordName(text)} />
                </div>
                <div className="col-12 flexed-col">
                    <label>ÐžÑ‚Ð·Ñ‹Ð²</label>
                    <textarea rows="2" className="ui_input requiredinput" type="text" name="comment" value={this.state.COMMENT} onChange={(text) => this.recordComment(text)}></textarea>
                </div>

                <div className="col-12">
                    <button type="button" onClick={() => this.leaveComment()} className="orange-button">
                        ÐžÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ Ð¾Ñ‚Ð·Ñ‹Ð²
                    </button>
                </div>
            </div>
        );
    }

	render()
    {
        if (this.props.USER) {
            return (
                <div id="flex-grow">
                    <span className="in-card-label mt">ÐžÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ Ð¾Ñ‚Ð·Ñ‹Ð²</span>
                    {this.renderForm()}
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CommentForm);